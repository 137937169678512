<template>
    <div class="form-body without-side">
      <div class="website-logo">
        <!-- <a href="index.html">
          <div class="logo">
            <img class="logo-size" src="@/assets/images/logo-light.svg" alt="Logo" />
          </div>
        </a> -->
      </div>
      <div class="iofrm-layout">
        <div class="img-holder">
          <div class="bg"></div>
          <div class="info-holder">
            <img src="@/assets/images/graphic8.svg" alt="Graphic" />
          </div>
        </div>
        <div class="form-holder">
          <div class="form-content">
            <div class="form-items">
              <div class="form-icon">
                <div class="icon-holder">
                  <img src="@/assets/images/icon1.svg" alt="Icon" />
                </div>
              </div>
              <h3 class="form-title-center">Register Now</h3>
              <form @submit.prevent="handleRegister">
                <input class="form-control custom-input" type="text" v-model="firstName" placeholder="First Name" required />
                <input class="form-control custom-input" type="text" v-model="lastName" placeholder="Last Name" required />
                <input class="form-control custom-input" type="email" v-model="email" placeholder="E-mail Address" required />
                <input class="form-control custom-input" type="password" v-model="password" placeholder="Password" required />
                <!-- <input class="form-control custom-input" type="text" v-model="country" placeholder="Business Name" required /> -->
                <!-- <input class="form-control custom-input" type="text" v-model="country" placeholder="Country" required /> -->
                <div class="form-button">
                  <button id="submit" type="submit" class="ibtn ibtn-full">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import * as SweetAlertConnector from "@/composables/SweetAlertConnector";
  import * as FastAPI from '@/composables/FastAPI'; // Assuming you may use it to handle registration logic
  
  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        password: ''
      };
    },
    methods: {
      async handleRegister() {
        console.log('Registering...', this.fullName, this.email, this.country);
        try {
          const userObject = {
            business_id: 7,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            password: this.password,
            role: "customer"
          }
          console.log("User Object", userObject)
          // Here you would call your registration API with user data
          const response = await FastAPI.createUser(userObject); // Update with your actual API call
          console.log('Registration response:', response);
          if (response) {
            console.log('Registration successful!');
            SweetAlertConnector.showSuccessAlert("Successfully registered!");
            this.$router.push({ path: '/login' }); // Redirect to login or another page after successful registration
          } else {
            console.error('Registration failed!');
            SweetAlertConnector.showErrorAlert('Registration failed. Please try again.');
          }
        } catch (error) {
          SweetAlertConnector.showErrorAlert('Registration failed. Please try again.');
          console.error('Registration failed!', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import '@/assets/css/iofrm-style.css';
  @import '@/assets/css/iofrm-theme27.css';
  
  /* Reusing styles from login page */
  .custom-input {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .custom-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .form-body.without-side {
    /* Add specific styles if needed */
  }
  </style>
  