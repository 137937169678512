import { jwtDecode } from "jwt-decode";

export const isAuthenticated = () => {
    // Example JWT token for testing
    const token = localStorage.getItem("accessToken");

    if (!token) {
        return false;
    }

    try {
        // Decode the JWT token
        const decoded = jwtDecode(token);

        // Get current time in seconds
        const currentTime = Math.floor(Date.now() / 1000);

        // Check if token is expired
        return decoded.exp > currentTime;
    } catch (e) {
        console.log(e);
        return false; // Invalid token or error in decoding
    }
};
