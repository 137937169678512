<template>
    <div>
      <h1>Entry Details</h1>
      <!-- Search field -->
      <input type="text" v-model="searchTerm" placeholder="Search entries" />
  
      <table class="table table-bordered table-striped">
        <thead class="sticky-header">
          <tr>
            <th scope="col">Entry #</th>
            <th>Consignee</th>
            <th>Waybill Number</th>
            <th>Entry Date</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredEntries" :key="entry.id">
            <td>{{ entry.entry_number }}</td>
            <td>{{ entry.waybill.importer.name }}</td>
            <td>{{ entry.waybill.waybill_number }}</td>
            <td>{{ entry.entry_date }}</td>
            <td>
              <!-- <button type="button" class="btn btn-success" @click="openEntry(entry.id, entry.entry_type)">Edit</button> -->
              <button class="btn btn-primary" @click="openEntry(entry.id, entry.entry_type)">
                <i class="fa-solid fa-folder-open"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
  </template>

<script>
import axios from 'axios';
import { ENTRY_TYPES } from '../constants';

export default {
  name: "AllEntries",
  props: {
    entryIds: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      entries: [], // To hold the fetched entries
      error: null, // To handle errors
      searchTerm: '', // To hold the search term
    };
  },
  created() {
    this.fetchEntries(); // Fetch entries when the page is created
  },
  methods: {
    async fetchEntries() {
        try {
            console.log('Fetching entries with IDs:', this.entryIds);

            // Ensure entryIds is an array
            const entryIdsArray = Array.isArray(this.entryIds) ? this.entryIds : this.entryIds.split(',').map(id => id.trim());

            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/entries-by-ids`, {
            params: { entry_ids: entryIdsArray }
            });

            this.entries = response.data;
        } catch (error) {
            this.error = error.message;
            console.error('There was an error fetching entries!', error);
        }
    },
    openEntry(entryId, entryType) {
      if (entryType === ENTRY_TYPES.VEHICLES) {
        this.$router.push({ name: 'VehicleEntryEditPage', params: { entryId } });
      } else {
        this.$router.push({ name: 'EntryDetails', params: { entryId } });
      }
    },
  },
  computed: {
    filteredEntries() {
      if (!this.searchTerm) return this.entries;

      const term = this.searchTerm.toLowerCase();
      return this.entries.filter(entry => {
        return (
          (entry.entry_number && entry.entry_number.toLowerCase().includes(term)) ||
          (entry.waybill_number && entry.waybill_number.toLowerCase().includes(term)) ||
          (entry.invoice_number && entry.invoice_number.split('; ').some(invoice_number => invoice_number.toLowerCase().includes(term))) ||
          (entry.importerName && entry.importerName.toLowerCase().includes(term))
        );
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.error-message {
  color: red;
}
</style>
  