<template>
    <h1>Entry Details</h1>
    <h2 v-if="isEditMode == true">Reference Number: {{ this.referenceNumber }}</h2>
    <div class="vehicle-form" v-if="isEditMode == false">
      <div class="row vehicleAttchments" >
        <div class="col-md-3">
          <label for="referenceNumber"><strong>Reference Number</strong></label>
          <input type="text" class="form-control" v-model="referenceNumber" placeholder="Enter Reference Number"/>
        </div>
        <div class="col-md-3">
            <label for="commercialInvoiceAttachment"><strong>Commercial Invoice Attachment</strong></label>
            <input type="file" id="commercialInvoiceAttachment" class="form-control" @change="handleFileChange($event, 'commercialInvoice')"/>
        </div>
        <div class="col-md-3">
            <label for="attachedSheet"><strong>Attached Sheet</strong></label>
            <input type="file" id="attachedSheet" class="form-control" @change="handleFileChange($event, 'attachedSheet')" />
          </div>
          <div class="col-md-3">
            <label for="instructionSheet"><strong>Instruction Sheet</strong></label>
            <input type="file" id="instructionSheet" class="form-control" @change="handleFileChange($event, 'instructionSheet')"/>
          </div>
      </div>
      <div>
        <div class="row">
          <div class="col-md-3">
            <label for="waybill"><strong>Waybill</strong></label>
            <input type="file" class="form-control"  @change="handleWaybillUpload" placeholder="Enter Waybill Number"/>
          </div>
            <div class="col-md-3">
              <div>-</div>
              <button type="button" class="btn btn-primary upload-button" @click="readToyotaFiles">Upload Files</button>
            </div>
        </div>
      </div>
    </div>
    
    
    <CustomsEntryForm ref="myCustomsForm" :customsEntryForm = "customsEntryForm" @update-customs-entry-form="customsEntryForm = $event"/>
    
    <div class="form-row">
      <div class="row">
        <div class="col-md-3">
          <label for="InvoiceNumber" class="form-label"><strong>Invoice Number</strong></label>
          <input type="text" class="form-control" v-model="invoiceNumber" id="InvoiceNumber" placeholder="Please Enter Invoice Number" />
        </div>
        <div class="col-md-3">
          <label for="supplierName" class="form-label"><strong>Supplier Name</strong></label>
          <!-- <input type="text" class="form-control" v-model="supplierName" id="supplierName" placeholder="Please Enter Supplier Name" /> -->
          <v-select
              v-model="supplier_id"
              :options="suppliers"
              label="name"
              id="SupplierName"
              :searchable="true"
              :reduce="(supplier) => supplier.id"
              :filterable="true"
              placeholder="Select a Supplier"
          ></v-select>
        </div>
        <div class="col-md-2">
          <label for="InvoiceDate" class="form-label"><strong>Invoice Date</strong></label>
          <input type="date" class="form-control" v-model="invoiceDate" id="InvoiceDate" placeholder="Please Enter Invoice Date" />
        </div>
        <div class="col-md-2">
          <label for="invoiceCurrency" class="form-label"><strong>Invoice Currency</strong></label>
          <v-select :options="currencies" v-model="invoiceCurrency" label="name" id="invoiceCurrency" :searchable="true" :filterable="true" :reduce="currency => currency.id"></v-select>
        </div>
        <div class="col-md-2">
          <label for="rateOfExchange" class="form-label"><strong>Rate of Exchange</strong></label>
          <input type="number" class="form-control" v-model="rateOfExchange" id="rateOfExchange" placeholder="Please Enter Rate of Exchange" />
        </div>
      </div>
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>Line #</th>
            <th>Product Code</th>
            <th>Description</th>
            <th>THN Number</th>
            <th>Duty</th>
            <th>VAT</th>
            <th>MVT</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Total Cost</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(vehicle, index) in vehicles" :key="index">
            <tr>
                <td>{{ index + 1 }}</td>
                <!-- <td> -->
                    <!-- <input type="text" v-model="vehicle.vehicleCode" class="form-control" placeholder="Please Enter Vehicle Code" /> -->
                <!-- </td> -->
                 <td contenteditable="true" @blur="updateVehicleData(vehicle, 'vehicleCode', $event.target.innerText)">{{ vehicle.vehicleCode }}</td>
                <!-- <td>
                    <input type="text" v-model="vehicle.description" class="form-control" placeholder="Please Enter Vehicle Model" />
                </td> -->
                <td contenteditable="true" @blur="updateVehicleData(vehicle, 'description', $event.target.innerText)">{{ vehicle.description }}</td>
                <!-- <td>
                    <input type="text" v-model="vehicle.thnNumber" @blur="updateTariffCode(vehicle)" @input="handleTariffInput(vehicle)" class="form-control" placeholder="Please Enter THN Number" />
                </td> -->
                <td 
                :class="[
                      vehicle.commodityCodeStatus === 'valid'
                          ? 'valid-commodity-code'
                          : 'invalid-commodity-code',
                  ]"
                  contenteditable="true" 
                  @blur="updateTariffCode(vehicle, $event.target.innerText)">
                  {{ vehicle.thnNumber }}
                </td>
                <td>
                    {{ vehicle.icdCharge }}%
                </td>
                <td>
                    {{ vehicle.vatCharge }}%
                </td>
                <td>
                    ${{ vehicle.mvtCharge }} per CC
                </td>
                <!-- <td>
                    <input type="number" v-model="vehicle.quantity" class="form-control" placeholder="Please Enter Quantity" />
                </td> -->
                <td contenteditable="true" @blur="updateVehicleData(vehicle, 'quantity', $event.target.innerText)">{{ vehicle.quantity }}</td>
                <!-- <td>
                    <input type="number" v-model="vehicle.unitPrice" class="form-control" placeholder="Please Enter Unit Price" />
                </td> -->
                <td contenteditable="true" @blur="updateVehicleData(vehicle, 'unitPrice', $event.target.innerText)">{{ vehicle.unitPrice }}</td>
                <td>
                  {{ caclulateVehicleTotalCost(vehicle) }}
                </td>
              <td>
                <button @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                    <i class="fas fa-chevron-up" style="color:gray;"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-chevron-down" style="color:gray;"></i>
                  </span>
                </button>
              </td>
              <td>
                <button class="btn btn-danger" @click="deleteVehicle(vehicles, index)">
                    <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr v-if="expandedIndex === index">
              <td colspan="12">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label for="">Fuel Type</label>
                        <select v-model="vehicle.fuelType" class="form-control">
                          <option disabled value="">Please select one</option>
                          <option v-for="fuelType in fuelTypeOptions" :key="fuelType.id" :value="fuelType.id">{{ fuelType.name }}</option>
                        </select>
                      </td>
                      <td>
                        <label for="">Seat Position</label>
                        <select v-model="vehicle.seatPosition" class="form-control">
                          <option disabled value="">Please select one</option>
                          <option v-for="position in seatPositionOptions" :key="position.id" :value="position.id">{{ position.name }}</option>
                        </select>
                      </td>
                      <td>
                        <label for="vehicleCode">Model Code</label>
                        <v-select :options="vehicleCodeOptions" v-model="vehicle.modelCode" :style="{ width: '300px' }" label="name" :searchable="true" :filterable="true" placeholder="Please select one" :reduce="vehicle => vehicle.id"></v-select>
                      </td>
                      <td>
                        <label for="numberOfSeats">Number of Seats</label>
                        <input type="number" v-model="vehicle.numberOfSeats" class="form-control" placeholder="Please Enter Number of Seats" />
                      </td>
                      <td>
                        <label for="numberOfDoors">Number of Doors</label>
                        <input type="number" v-model="vehicle.numberOfDoors" class="form-control" placeholder="Please Enter Number of Doors" />
                      </td>
                      <td>
                        <label for="tyreSize">Tyre Size</label>
                        <input type="text" v-model="vehicle.tyreSize" class="form-control" placeholder="Please Enter Tyre Size" />
                      </td>
                      <td>
                        <label for="engineDisplacement">Engine Displacement</label>
                        <input type="text" v-model="vehicle.engineDisplacement" class="form-control" placeholder="Please Enter Engine Displacement" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr v-if="expandedIndex === index">
              <td colspan="12">
                <table>
                  <thead>
                    <tr>
                      <th>Chassis Number</th>
                      <th>Engine Number</th>
                      <th>Exterior Color</th>
                      <th>Customer Name</th>
                      <th>Customer TIN</th>
                      <th>Broker Instructions</th>
                      <th>CPC Code</th>
                      <th>NPC Code</th>
                      <th>ED Number</th>
                      <th>Manufacture Year</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="detail in vehicle.details" :key="detail.chassisNumber">
                        <!-- <td> -->
                            <!-- <input type="text" v-model="detail.chassisNumber" class="form-control" placeholder="Please Enter Chassis Number" /> -->
                            <td contenteditable="true" @blur="updateVehicleDetialData(detail, 'chassisNumber', $event.target.innerText)">{{ detail.chassisNumber}}</td>
                        <!-- </td> -->
                        <td>
                            <input type="text" v-model="detail.engineNumber" class="form-control" placeholder="Please Enter Engine Number" />
                        </td>
                        <td>
                            <input type="text" v-model="detail.exteriorColor" class="form-control" placeholder="Please Enter Exterior Color" />
                        </td>
                        <td>
                            <input type="text" v-model="detail.customerName" class="form-control" placeholder="Please Enter Customer Name" />
                        </td>
                        <td>
                            <input type="text" v-model="detail.customerTIN" class="form-control" placeholder="Please Enter Customer TIN" />
                        </td>
                        <td>
                          <select v-model="detail.brokerInstructions" class="form-control">
                              <option disabled value="">Please select one</option>
                              <option v-for="instruction in instructionList" :key="instruction.code" :value="instruction.code">{{ instruction.code }}</option>
                            </select>
                        </td>
                        <td>
                          <v-select :options="availableCPCCodes" v-model="detail.cpcCode" label="code" id="cpcCode" :searchable="true" :filterable="true" :reduce="cpc => cpc.cpcId"></v-select>
                        </td>
                        <td>
                          <v-select v-model="detail.npcCode" :options="npcCodeOptions" label="code" id="npcCode" :searchable="true" :reduce="npc => npc.code" :filterable="true" placeholder="Select a NPC Code"></v-select>
                        </td>
                        <td>
                          <input type="text" v-model="detail.edNumber" class="form-control" placeholder="Please Enter ED Number" />
                        </td>
                        <td>
                          <v-select :options="years" v-model="detail.manufactureYear" :searchable="true" :filterable="true" placeholder="Please select one"></v-select>
                        </td>
                        <td>
                          <button class="btn btn-danger" @click="deleteRow(vehicle.details, index)">
                              <i class="fa fa-trash"></i>
                          </button>
                        </td>
                    </tr>
                    <tr v-if="expandedIndex === index">
                        <td colspan="12">
                            <button type="button" class="btn btn-primary" @click="addChassis(vehicle)">Add Chassis</button>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="form-row">
            <div class="col-md-1">
                <button type="button" class="btn btn-primary" @click="addVehicle()">
                    Add Vehicle
                </button>
            </div>
        </div>
      <h3>Footer Information</h3>
      <table>
        <thead>
          <tr>
            <th>Invoice Total</th>
            <th>Inland</th>
            <th>Insurance</th>
            <th>Other Charges</th>
            <th>Final Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td contenteditable="true" @blur="updateInvoiceData('invoiceTotal', $event.target.innerText)" :style="naStyle(invoiceTotal)">{{ invoiceTotal || 0.00 }}</td>
            <td contenteditable="true" @blur="updateInvoiceData('inlandAmount', $event.target.innerText)" :style="naStyle(inlandAmount)">{{ inlandAmount || 0.00 }}</td>
            <td contenteditable="true" @blur="updateInvoiceData('insuranceAmount', $event.target.innerText)" :style="naStyle(insuranceAmount)">{{ insuranceAmount || 0.00 }}</td>
            <td contenteditable="true" @blur="updateInvoiceData('otherCharges', $event.target.innerText)" :style="naStyle(otherCharges)">{{ otherCharges || 0.00 }}</td>
            <td >{{ finalTotal }}</td>
          </tr>
        </tbody>
      </table>
      <h3>Calculated Check</h3>
      <table>
        <thead>
          <tr>
            <th>Total Extension Prices</th>
            <th>Number of Line Items</th>
            <th>Matches Invoice Total?</th>
          </tr>
        </thead>
        <tbody>
          <tr :style="compareInvoiceStyle()">
            <td>{{ calculatedTotal.toLocaleString('en-US', {minimumFractionDigits: 2}) }}</td>
            <td>{{ vehicles.length }}</td>
            <td>{{ compareInvoiceTotal() ? 'Yes': 'No' }}</td>
          </tr>
        </tbody>
      </table>
        
    </div>
    <div >
      <h3>Entry Summary</h3>
      <table>
        <thead>
          <tr>
            <th>Number of Lines Read</th>
            <th>Total Number of Vehicles Read</th>
            <th>Total Invoices Value</th>
          </tr>
        </thead>
       <tbody>
        <tr>
          <td>{{ vehicles.length }}</td>
          <td>{{ totalNumberOfVehicles }}</td>
          <td>{{ calculatedTotal.toLocaleString('en-US', {minimumFractionDigits: 2}) }}</td>
        </tr>
        
       </tbody>
      </table>
      <table>
        <tbody>
          <tr>
          <td><button type="button" class="btn btn-primary" @click="handleButtonClick()">{{ isEditMode ? 'Save Entry' : 'Create Entry' }}</button></td>
          <td><button type="button" class="btn btn-primary" @click="generateWorksheet()">Generate WorkSheet</button></td>
          <td><button type="button" class="btn btn-primary" @click="generateAsycudaXML()">Generate Asycuda XML</button></td>
          <td><button type="button" class="btn btn-primary" @click="goToValuationFormPage()">Generate Valuation Form</button></td>
          <td>
            <button type="button" class="btn btn-primary" @click="generateTTC84">
                  Generate Special Exemption
              </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import CustomsEntryForm from './CustomsEntry/CustomsEntryForm.vue';
  import * as DatabaseConnector from '@/composables/DatabaseConnector';
  import * as SweetAlertConnector from '../composables/SweetAlertConnector';
  import * as Utilities from '@/composables/Utilities';
  import * as Configuration from '@/composables/BrokerConfiguration';
  import { ENTRY_TYPES, ENTRY_STATUS } from '../constants'

  import * as FastAPI from '@/composables/FastAPI';
  export default {
    name: 'VehicleEntry',
    components: {
      CustomsEntryForm
    },
    props: {
      record: {
        type: Object,
        default: () => (Utilities.initializeCustomsEntryForm()),
      },
      vehicleDetails: {
        type: Array,
        default: () => [],
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      referenceNumberRecord: {
        type: String,
        default: '',
      },
      invoiceDetails: {
        type: Object,
        default: () => ({
          invoice_id: '',
          supplierName: '',
          supplierId: '',
          invoiceNumber: '',
          invoiceDate: '',
          invoiceCurrency: '',
          rateOfExchange: 0.0,
          invoiceTotal: 0.0,
          inlandAmount: 0.0,
          insuranceAmount: 0.0,
          otherCharges: 0.0,
        }),
      },
    },
    watch: {
      record: {
        handler: function (newVal) {
          this.customsEntryForm = newVal;
        },
        deep: true,
      },
      vehicleDetails: {
        handler: function (newVal) {
          this.vehicles = newVal;
        },
        deep: true,
      },
      referenceNumberRecord: {
        handler: function (newVal) {
          this.referenceNumber = newVal;
        },
      },
      invoiceDetails: {
        handler: function (newVal) {
          this.supplierName = newVal.supplierName;
          this.invoiceNumber = newVal.invoiceNumber;
          this.invoiceDate = newVal.invoiceDate;
          this.invoiceCurrency = newVal.invoiceCurrency;
          this.rateOfExchange = newVal.rateOfExchange;
          this.invoiceTotal = newVal.invoiceTotal;
          this.inlandAmount = newVal.inlandAmount;
          this.insuranceAmount = newVal.insuranceAmount;
          this.otherCharges = newVal.otherCharges;
        },
        deep: true,
      },
      "customsEntryForm.waybillDate"(newDate) {
            this.updateRates(newDate);
      },
    },
    emits: ['submit'],
    data() {
      return {
        files: {
          commercialInvoice: null,
          attachedSheet: null,
          instructionSheet: null,
        },
        referenceNumber: '',
        supplierName: '',
        supplier_id: '',
        invoiceNumber: '',
        invoiceDate: '',
        invoiceCurrency: '',
        baseCurrencyId: 0,
        rateOfExchange: 0.0,
        rateOfExchangeId: 0,
        invoiceTotal: 0.0,
        inlandAmount: 0.0,
        insuranceAmount: 0.0,
        otherCharges: 0.0,
        vehicles: [],
        waybillFiles: [],
        suppliers: [],
        seatPositionOptions : [],
        instructionList: [
          {detail: 'Exempted but Bond then Extract', code: 'EB'},
          {detail: 'Exempted clear off the Port', code: 'EC'},
          {detail: 'Exempted from all duties and taxes but processing like a duty paid', code: 'DP'},
          {detail: 'Bond', code: 'B'},
          {detail: 'Duty Paid', code: 'DP'},
        ],
        fuelTypeOptions: [],
        vehicleCodeOptions: [],
        currencies: [],
        years: [],
        countries: [],
        npcCodeOptions: ['000'],
        expandedIndex: null,
        customsEntryForm: {...this.record},
      };
    },
    mounted() {
      this.setYears();
      // this.vehicleCodeOptions = VehicleList.getVehiclesList();
      this.getCurrencies();
      this.pageSetup();
      this.fetchNpcCodes();
      this.fetchCountries();
      this.getSuppliers();
      this.getSeatingPositions();
      this.getFuelTypes();
      this.getVehicleModels();
    },
    computed: {
      totalNumberOfVehicles(){
        return this.vehicles.reduce((total, vehicle) => {return total + vehicle.details.length;}, 0);
      },
      finalTotal(){
        const invoiceTotal = parseFloat(this.invoiceTotal) || 0.00;
        const inlandAmount = parseFloat(this.inlandAmount) || 0.00;
        const insuranceAmount = parseFloat(this.insuranceAmount) || 0.00;
        const otherCharges = parseFloat(this.otherCharges) || 0.00;

        const total = invoiceTotal + inlandAmount + insuranceAmount + otherCharges;
        return total.toLocaleString('en-US', {minimumFractionDigits: 2})

      },
      calculatedTotal(){
        const total = this.vehicles.reduce((acc, vehicle) => {
          const currentTotal = this.convertToFloat(vehicle.quantity) * this.convertToFloat(vehicle.unitPrice);
          return acc + currentTotal
        }, 0);
        return total;
      },
      availableCPCCodes(){
        return this.customsEntryForm.regimeTypeOptions.flatMap(regime => regime.cpcCodes)
      },
      allAvailableNPCCodes(){
        const npcCodesArray = [];

        this.availableCPCCodes.forEach(item => {
            if (item.npcCodes && Array.isArray(item.npcCodes)) {
            npcCodesArray.push(...item.npcCodes);
            }
        });
        return npcCodesArray;
      }
    },
    methods: {
      async getCurrencies(){
          try {
              this.currencies = await FastAPI.getCurrencies();
              this.invoiceCurrency = this.currencies.find(currency => currency.code === 'USD').id;
              this.baseCurrencyId = this.currencies.find((currency) => currency.code === "TTD").id;
          }
          catch (error) {
              console.error("Error fetching currencies", error);
          }
      },
      handleWaybillUpload(event) {
        console.log("Handling Waybill Upload")
        this.waybillFiles = Array.from(event.target.files);
        
      },
      updateVehicleDetialData(detail, field, value){
        detail[field] = value;
      },
      updateVehicleData(vehicle, field, value){
        vehicle[field] = value;
      },
      deleteRow(data, itemIndex){
        data.splice(itemIndex, 1);
      },
      deleteVehicle(data, itemIndex){
        data.splice(itemIndex, 1);
      },
      async generateTTC84(){
        console.log("Generating TTC84");
          let c84Obj = {
              declarationNumber: this.referenceNumber,
              declarationDate: Utilities.getCurrentDateString(),
              regimeCode: "C4",
              declarantName: "RAMNARINE RAMPERSAD",
              declarantNumber: "BR0285",
              referenceNumber: this.referenceNumber,
              importer: this.customsEntryForm.importerName,
              importNumber: "",
              declarationContent: "",
              CPCCode: "4000",
              extensionCode: "",
              signatoryName: "",
              signatoryStatus: "",
              presigned: false,
          };
          const selectedImporter = this.customsEntryForm.importers.find(
              (importer) => importer.id === this.customsEntryForm.importerId
          );
          const importerTaxIdentificationNumber = selectedImporter?.taxIdentificationNumber;
          c84Obj.importNumber = importerTaxIdentificationNumber;
          let signatories = await FastAPI.getDeclarants();
          console.log("Signatories: ", signatories);
          signatories = signatories.filter(
              (signatory) => signatory.importerCode === this.customsEntryForm.importerId
          );
          DatabaseConnector.getSpecialExemptionDeclarations(this.customsEntryForm.importerId)
              .then((response) => {
                  const declarationNumbers = response.map((declaration) => declaration.declaration_number);
                  SweetAlertConnector.form(declarationNumbers, signatories).then((selection) => {
                      c84Obj.presigned = selection.generatePreSigned;
                      const selectedDeclearationIndex = parseInt(selection.select1);
                      const selectedDelcaration = response[selectedDeclearationIndex];
                      c84Obj.declarationContent = `${selectedDelcaration.content.replace(/\\n/g, "\n")}`;
                      c84Obj.extensionCode = selectedDelcaration.extensionCode;

                      const selectedSignatory = signatories.find(
                          (signatory) => signatory.id === parseInt(selection.select2)
                      );
                      c84Obj.signatoryName = selectedSignatory.name;
                      c84Obj.signatoryStatus = selectedSignatory.status;
                      DatabaseConnector.generateC84Form(c84Obj)
                          .then((response) => {
                              SweetAlertConnector.closeCurrentAlert();
                              SweetAlertConnector.showSuccessAlert("C84 Form Generated Successfully!");
                              const fileURL = window.URL.createObjectURL(
                                  new Blob([response], {
                                      type: "application/pdf",
                                  })
                              );
                              const fileLink = document.createElement("a");
                              const fileName = `C84 Form - ${this.entryDetails?.entry_number}.pdf`;
                              fileLink.href = fileURL;
                              fileLink.setAttribute("download", fileName);
                              document.body.appendChild(fileLink);
                              fileLink.click();
                              fileLink.remove();
                          })
                          .catch((error) => {
                              console.error("Failed to generate C84 Form:", error);
                              SweetAlertConnector.showErrorAlert("Failed to generate C84 Form!");
                          });
                  });
                  // c84Obj.specialExemptionDeclarations = response
              })
              .catch((error) => {
                  console.error("Failed to get special exemption declarations:", error);
              });
          console.log("C84 Object: ", c84Obj);
      },
      goToValuationFormPage(){
        const invoiceNumbers = this.invoiceNumber;
        let netPrice = this.invoiceTotal;
        if(this.customsEntryForm.incoTerms === 'CIF'){
          netPrice = parseFloat(this.invoiceTotal) + parseFloat(this.customsEntryForm.freightAmount) + parseFloat(this.customsEntryForm.insuranceAmount) + parseFloat(this.customsEntryForm.otherCharges);
        }
        this.$router.push({
          name: "CustomsDeclarationForm",
          query: {
            buyerId: this.customsEntryForm.importerId,
            sellerId: this.customsEntryForm.exporterId,
            invoiceNumber: invoiceNumbers,
            invoiceTotal: netPrice,
            currency: this.invoiceCurrency,
            rateOfExchange: this.rateOfExchange,
            freightAmount: this.customsEntryForm.freightAmount * this.customsEntryForm.freightRateOfExchange,
            insurance: this.customsEntryForm.insuranceAmount * this.customsEntryForm.insuranceRateOfExchange,
            incoTerms: this.customsEntryForm.incoTerms,
            referenceNumber: this.referenceNumber,
          }
        })
      },
      handleTariffInput(vehicle){
        console.log("Handling Tariff Input")
        const value = vehicle.thnNumber;
        console.log("Value: ", value)
        // Remove any existing dots and non-numeric characters
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        console.log("Sanitized Value: ", sanitizedValue)
        // Format the value as XXXX.XX.XX
        const formattedValue = this.formatTariffCode(sanitizedValue);
        console.log("Formatted Value: ", formattedValue)
        // Update the text content of the cell
        vehicle.thnNumber = formattedValue;
        // this.moveCursorToEnd(event.target);
        // Update the item data after handling the input
      },
      formatTariffCode(value){
        let formattedValue = "";
        // Insert dots after the first four characters and after the next two characters
        for (let i = 0; i < value.length; i++) {
            if (i === 4 || i === 6 || i === 8) {
                formattedValue += ".";
            }
            formattedValue += value[i];
        }
        return this.truncateString(formattedValue);
      },
      updateTariffCode(selectedVehicle, commodityCode){
        const value = commodityCode;
        this.updateVehicleData(selectedVehicle, 'thnNumber', value);
        console.log("Updating Tariff Code", value)
        // const selectedVehicle = this.vehicles[vehicleIndex];
        // console.log("Selected Vehicle: ", selectedVehicle)
        if (value === undefined || value === null || value.trim().length === 0 || value === "N/A") {
          selectedVehicle.icdCharge = 0;
          selectedVehicle.vatCharge = 0;
          selectedVehicle.mvtCharge = 0;
          return;
        }
        FastAPI.getCommodityCodeCharges(value.replace(/\./g, "")).then((chargesResponse) => {

          const commodityCodeId = chargesResponse.id;
          let icdObject = Utilities.findChargeObject(chargesResponse, 'import custom duty')
          const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject.charge_amount);

          const vatObject = Utilities.findChargeObject(chargesResponse, 'value added tax')
          const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject.charge_amount);

          let mvtObject = Utilities.findChargeObject(chargesResponse, 'motor vehicle tax')
          const mvtCharge = mvtObject === undefined ? 0 : parseFloat(mvtObject.charge_amount);

          selectedVehicle.icdCharge = icdCharge;
          selectedVehicle.vatCharge = vatCharge;
          selectedVehicle.mvtCharge = mvtCharge;
          selectedVehicle.commodityCodeId = commodityCodeId;
          selectedVehicle.commodityCodeStatus = 'valid';
        })
        .catch((error) => {
          console.error("Error fetching charges: ", error);
          selectedVehicle.icdCharge = 0;
          selectedVehicle.vatCharge = 0;
          selectedVehicle.mvtCharge = 0;
          selectedVehicle.commodityCodeStatus = 'invalid';
          selectedVehicle.commodityCodeId = 0;

        });
      },
        createBlankChassis(){
            const chassis = {
                chassisNumber: "",
                engineNumber: "",
                exteriorColor: "",
                interiorColor: "",
                customerName: "",
                customerTIN: "",
                brokerInstructions: "DP",
                cpcCode: Configuration.getConfigurations().defaultCPCCode,
                npcCode: "000",
                edNumber: "",
                quantity: 1,
                grossWeight: 0,
                netWeight: 0,
                engineDisplacement: "",
                manufactureYear: "2000",
            };
            return chassis;
        },
        updateRates(shippedOnBoardDate){
          const chargeCurrencies = [
              this.customsEntryForm.freightCurrency,
              this.customsEntryForm.insuranceCurrency,
              this.customsEntryForm.otherChargesCurrency,
              this.invoiceCurrency,
          ];
          const allCurrencies = [...chargeCurrencies];
          const uniqueCurrencies = [...new Set(allCurrencies)];

          Utilities.getRatesOfExchange(uniqueCurrencies, shippedOnBoardDate).then((rates) => {
              this.customsEntryForm.ratesOfExchange = rates;
              if(this.invoiceCurrency != null && this.invoiceCurrency != undefined && this.invoiceCurrency != ''){
                const invoiceRate = rates.find((rate) => rate.base_currency_id === this.invoiceCurrency);
                this.rateOfExchange = invoiceRate ? invoiceRate.exchange_rate : 1;
                this.rateOfExchangeId = invoiceRate ? invoiceRate.id : 1;
              }
              else {
                this.rateOfExchange = 1;
                this.rateOfExchangeId = 0;
              }
          });
        },
        createBlankVehicle(){
            const vehicle = {
                vehicleCode: "",
                description: "",
                thnNumber: "",
                quantity: 1,
                unitPrice: 0,
                grossWeight: 0,
                netWeight: 0,
                curbWeight: 0,
                engineDisplacement: "",
                fuelType: "PET",
                numberOfSeats: 5,
                numberOfDoors: 4,
                tyreSize: "",
                details: [this.createBlankChassis()],
            };
            return vehicle;
        },
        addVehicle() {
            this.vehicles.push(this.createBlankVehicle());
        },
        addChassis(vehicle) {
            vehicle.details.push(this.createBlankChassis());
        },
      compareInvoiceStyle(){
        return this.compareInvoiceTotal() ? { }: {backgroundColor: 'red', color: 'white' };
      },
      compareInvoiceTotal(){
        const invoiceTotal = parseFloat(this.invoiceTotal) || 0.00;
        const calculatedTotal = parseFloat(this.calculatedTotal) || 0.00;
        // console.log("Invoice Total: ", invoiceTotal, "Calculated Total: ", calculatedTotal)
        // console.log("Comparison: ", invoiceTotal === calculatedTotal)
        return invoiceTotal === calculatedTotal
      },
      updateInvoiceData(field, value){
        this[field] = value;
      },
      naStyle(value) {
        if ([null, undefined, '', false].includes(value)) {
          return { backgroundColor: 'yellow', color: 'black' };
        }
        return {};
      },
      convertToFloat(value){
        if(value === null || value === undefined || value === ''){
          return 0.00;
        }
        if(!isNaN(value)){
          return parseFloat(value);
        }
        let processedValue = value.replace(/[^0-9.]/g, '');
        return parseFloat(processedValue);
      },
      caclulateVehicleTotalCost(vehicle){
        // return parseFloat(vehicle.quantity) * parseFloat(vehicle.unitPrice);
        let totalCost = this.convertToFloat(vehicle.quantity) * this.convertToFloat(vehicle.unitPrice);
        vehicle.extensionPrice = totalCost;
        return parseFloat(totalCost).toLocaleString('en-US', {minimumFractionDigits: 2})
      },
      handleButtonClick(){
        if (this.isEditMode) {
          console.log("Updating Entry")
          this.updateEntry();
        } else {
          console.log("Saving Entry")
          // this.saveEntry();
          this.saveEntryFastAPI();
        }
      },
      sanitizeVehicleData(){
        console.log("Sanitizing Vehicle Data")
        console.log("Vehicles: ", this.vehicles)
        return this.vehicles.flatMap(vehicle =>
          vehicle.details.map(detail => ({
            
            description: vehicle.description,
            quantity: detail.quantity,
            unit_price: parseFloat(vehicle.unitPrice),
            amount: parseFloat(detail.quantity) * parseFloat(vehicle.unitPrice),
            thn_code: vehicle.thnNumber,
            product_code: vehicle.vehicleCode,
            country_of_origin: '',
            cpcCode: detail.cpcCode,
            npcCode: detail.npcCode,
            vatApplicable: true,

            grossWeight: vehicle.grossWeight,
            netWeight: vehicle.netWeight,
            curbWeight: vehicle.curbWeight,
            fuelType: vehicle.fuelType,
            seatPosition: vehicle.seatPosition,
            modelCode: vehicle.modelCode,
            numberOfSeats: vehicle.numberOfSeats,
            numberOfDoors: vehicle.numberOfDoors,
            tyreSize: vehicle.tyreSize,
            engineDisplacement: vehicle.engineDisplacement,
            chassisNumber: detail.chassisNumber,
            engineNumber: detail.engineNumber,
            exteriorColor: detail.exteriorColor,
            customerName: detail.customerName,
            tinNumber: detail.customerTIN,
            brokerInstructions: detail.brokerInstructions,
            edNumber: detail.edNumber,
            manufactureYear: detail.manufactureYear,
          }))
        )
      },
      updateWaybill(){
        return {
          waybill_number: this.customsEntryForm.waybillNumber,
          waybill_date: this.customsEntryForm.waybillDate,
          mode_of_transport: this.customsEntryForm.modeOfTransport,
          
          freight_type: this.customsEntryForm.freightType,
          marks_and_numbers: this.customsEntryForm.marksAndNumbers,
          package_type: this.customsEntryForm.packageType,
          package_quantity: this.customsEntryForm.numberOfPackages,
          country_of_origin: this.customsEntryForm.country_of_origin,
          country_of_final_destination: this.customsEntryForm.countryOfFinalDestination,
          importer: this.customsEntryForm.importerId,
          shipper: this.customsEntryForm.exporterId,
          vessel: this.customsEntryForm.vesselId,
          port_of_discharge: this.customsEntryForm.portOfDischargeId,
          waybill_id: this.customsEntryForm.waybill_id
        } 
      },
      formatInvoiceData(){
        return {
          invoice_id: '',
          invoice_number: this.invoiceNumber,
          invoice_date: this.invoiceDate,
          invoice_total: this.invoiceTotal,
          sub_total: this.invoiceTotal,
          supplier_name: this.supplierName,
          taxed_amount: 0.00,
          supplier_address: '',
          purchase_order_number: '',
          inland: this.inlandAmount === '' ? 0.00 : this.inlandAmount,
          insurance: this.insuranceAmount === '' ? 0.00 : this.insuranceAmount,
          other_charges: this.otherCharges === '' ? 0.00 : this.otherCharges,
          entry_id: '',
          currency: this.invoiceCurrency,
          rate_of_exchange: this.rateOfExchange,
        }
      },
      formatInvoiceLines(){
        return this.vehicles.flatMap(vehicle => {
          return vehicle.details.map(detail => {
            return {
              description: vehicle.description,
              quantity: 1,
              unit_price: vehicle.unitPrice,
              amount: parseFloat(vehicle.unitPrice),
              tariff_code: vehicle.thnNumber,
              product_code: vehicle.vehicleCode,
              country_of_origin: '',
              cpc_code: detail.cpcCode,
              npc_code: detail.npcCode,
              vatApplicable: true,
              invoice_line_id: detail.invoiceLineId,
              
            }
          })
        });
      },
      formatVehicleLines(){
        return this.vehicles.flatMap(vehicle => {
          return vehicle.details.map(detail => {
            return {
              id: detail.id,
              gross_weight: vehicle.grossWeight,
              net_weight: vehicle.netWeight,
              curb_weight: vehicle.curbWeight,
              fuel_type: vehicle.fuelType,
              seat_position: vehicle.seatPosition,
              model_code: vehicle.modelCode,
              number_of_seats: vehicle.numberOfSeats,
              number_of_doors: vehicle.numberOfDoors,
              tyre_size: vehicle.tyreSize,
              engine_displacement: vehicle.engineDisplacement,
              chassis_number: detail.chassisNumber,
              engine_number: detail.engineNumber,
              exterior_color: detail.exteriorColor,
              customer_name: detail.customerName,
              tin_number: detail.customerTIN,
              broker_instructions: detail.brokerInstructions,
              ed_number: detail.edNumber,
              manufacture_year: detail.manufactureYear,
            }
          })
        });
      },
      updateEntryData(){
        return {
          net_weight: this.customsEntryForm.netWeight,
          gross_weight: this.customsEntryForm.grossWeight,
          entry_number: this.referenceNumber,
          freight_charge: this.customsEntryForm.freightAmount,
          declarant: this.customsEntryForm.declarantId,
          incoterms: this.customsEntryForm.incoTerms,
          regime_type: this.customsEntryForm.regimeType,
          deposit: this.customsEntryForm.depositAmount,
          container_charges: this.customsEntryForm.containerCharges,
          additional_charges: this.customsEntryForm.additionalCharges,
          local_fee: this.customsEntryForm.localFee,
          country_last_provenance: this.customsEntryForm.countryLastProvinance,
          trading_country: this.customsEntryForm.tradingCountry,
        }
      },
      updateEntry(){
        const waybillData = this.updateWaybill();
        const entryData = this.updateEntryData();
        const invoiceData = this.formatInvoiceData();
        const invoiceLines = this.formatInvoiceLines();
        console.log("Invoice Lines: ", invoiceLines)
        const vehicleLines = this.formatVehicleLines();

        
        const payload = {
          waybill: waybillData,
          entry: entryData,
          invoice: invoiceData,
          invoiceLines: invoiceLines,
          vehicleLines: vehicleLines,
        };
        console.log("Generating Payload", payload)
        this.$emit('submit', payload);
      },
      async getSeatingPositions(){
        try {
          console.log("Fetching Seating Positions")
          this.seatPositionOptions = await FastAPI.getSeatingPositions();
          console.log("Seating Positions: ", this.seatPositionOptions)
        }
        catch (error) {
          console.error("Error fetching seating positions", error);
        }
      },
      async getFuelTypes(){
        try {
          console.log("Fetching Fuel Types")
          this.fuelTypeOptions = await FastAPI.getFuelTypes();
          console.log("Fuel Types: ", this.fuelTypeOptions)
        }
        catch (error) {
          console.error("Error fetching fuel types", error);
        }
      },
      async getVehicleModels(){
        try {
          console.log("Fetching Vehicle Models")
          this.vehicleCodeOptions = await FastAPI.getVehicleModels();
          console.log("Vehicle Models: ", this.vehicleCodeOptions)
        }
        catch (error) {
          console.error("Error fetching vehicle models", error);
        }
      },
      prepareEntryDataFastAPI(){
        console.log("Saving Entry");
          const entryObject = {
              entry_date: Utilities.getDateStringInputField(),
              entry_number: this.referenceNumber,
              declarant_id: this.customsEntryForm.declarantId,
              regime_type_id: this.customsEntryForm.regimeType,
              local_charge: this.customsEntryForm.localFee,
              deposit_fee: this.customsEntryForm.depositAmount,
              container_charges: this.customsEntryForm.containerCharges,
              manifest_reference: "",
              country_of_first_destination_id: this.customsEntryForm.countryLastProvinance,
              trading_country_id: this.customsEntryForm.tradingCountry,
              country_of_export_id: this.customsEntryForm.countryOfOrigin,
              country_of_destination_id: this.customsEntryForm.countryOfFinalDestination,
              delivery_terms_id: this.customsEntryForm.incoTerms,
              delivery_place: "",
              status: "STORED_AND_NOT_CLASSIFIED"
          }
          return entryObject;
      },
      prepareWaybillDataFastAPI(){
        const waybillObject = {
          waybill_number: this.customsEntryForm.waybillNumber,
          waybill_date: this.customsEntryForm.waybillDate,
          shipped_on_board_date: this.customsEntryForm.waybillDate,
          freight_type: this.customsEntryForm.freightType,
          mode_of_transport_id: this.customsEntryForm.modeOfTransport,
          freight_type_id: this.customsEntryForm.freightType,
          voyage_flight_number: "",
          description_of_goods: "",
          country_of_origin_id: this.customsEntryForm.countryOfOrigin,
          package_type_id: this.customsEntryForm.packageType,
          package_quantity: this.customsEntryForm.numberOfPackages,
          vessel_id: this.customsEntryForm.vesselId,
          gross_weight: this.customsEntryForm.grossWeight,
          importer_id: this.customsEntryForm.importerId,
          exporter_id: this.customsEntryForm.exporterId,
          office_of_destination_id: this.customsEntryForm.portOfDischargeId,
          office_of_entry_exit_id: this.customsEntryForm.portOfLoadingId,
          international_freight: {
              amount: this.customsEntryForm.freightAmount,
              currency_id: this.customsEntryForm.freightCurrency,
              rate_of_exchange_id: this.customsEntryForm.freightExchangeRateId
          },
          insurance: {
              amount: this.customsEntryForm.insuranceAmount,
              currency_id: this.customsEntryForm.insuranceCurrency,
              rate_of_exchange_id: this.customsEntryForm.insuranceExchangeRateId
          },
          other_charges: {
              amount: this.customsEntryForm.otherCharges,
              currency_id: this.customsEntryForm.otherChargesCurrency,
              rate_of_exchange_id: this.customsEntryForm.otherChargesExchangeRateId
          }
        };
        return waybillObject;
      },
      prepareInvoiceDataFastAPI(){
        const invoiceLines = this.prepareInvoiceLineDataFastAPI();
        const invoiceObject = {
          invoice_number: this.invoiceNumber,
          invoice_date: this.invoiceDate,
          invoice_total: parseFloat(this.invoiceTotal),
          supplier_id: this.supplier_id, 
          purchase_order_number: "",
          inland: this.inlandAmount,
          insurance: this.insuranceAmount,
          other_charges: this.otherCharges,
          currency_id: this.invoiceCurrency,
          rate_of_exchange_id: this.rateOfExchangeId,
          invoice_lines: invoiceLines,
        }
        return invoiceObject;
      },
      prepareInvoiceLineDataFastAPI(){
        return this.vehicles.flatMap(vehicle => {
          return vehicle.details.map(detail => {
            const cpcNpcRelationId = Utilities.findCPCNPCReltation(detail.cpcCode, detail.npcCode, this.availableCPCCodes);
            return {
              description: vehicle.description,
              quantity: 1,
              unit_price: Utilities.parseFloatFromString(vehicle.unitPrice),
              extension_price: Utilities.parseFloatFromString(vehicle.unitPrice),
              discounted_price: 0.00,
              commodity_code_id: vehicle.commodityCodeId,
              product_code: vehicle.vehicleCode,
              country_of_origin_id: this.customsEntryForm.countryOfOrigin, 
              cpc_code: detail.cpcCode, 
              npc_code: detail.npcCode,
              cpc_npc_code_id: cpcNpcRelationId,
              package_type_id: this.customsEntryForm.packageType,
              package_quantity: this.customsEntryForm.numberOfPackages,
              
            }
          })
        });
      },
      getBrandId(selectedModelId){
        const vehicle = this.vehicleCodeOptions.find(model => model.id === selectedModelId);
        return vehicle ? vehicle.brand_id : 0;
      },
      prepareVehicleLineDataFastAPI(){
        return this.vehicles.flatMap(vehicle => {
          return vehicle.details.map(detail => {
            return {
              chassis_number: detail.chassisNumber,
              engine_number: detail.engineNumber,
              engine_displacement: Utilities.parseFloatFromString(vehicle.engineDisplacement),
              unladen_weight: 0,
              fuel_type_id: vehicle.fuelType,
              brand_id: this.getBrandId(vehicle.modelCode), 
              vehicle_model_id: vehicle.modelCode,
              manufacture_year: parseInt(detail.manufactureYear),
              colour: detail.exteriorColor,
              number_of_doors: parseInt(vehicle.numberOfDoors),
              seating_capacity: parseInt(vehicle.numberOfSeats),
              condition: "NEW",
              is_showroom_vehicle: false,
              seating_position_id: vehicle.seatPosition,

              tyre_size: vehicle.tyreSize,
              customer_name: detail.customerName,
              tin_number: detail.customerTIN,
              broker_instructions: detail.brokerInstructions,
              ed_number: detail.edNumber,
            }
          })
        });
      },
      saveEntryFastAPI(){
        const entryObject = this.prepareEntryDataFastAPI();
        const waybillObject = this.prepareWaybillDataFastAPI();
        console.log("Vehicle List", this.vehicles);
        const invoiceData = this.prepareInvoiceDataFastAPI();
        const vehicleData = this.prepareVehicleLineDataFastAPI();
        console.log("Invoice Data", invoiceData);
        console.log("Entry Object", entryObject);
        console.log("Waybill Object", waybillObject);
        console.log("Vehicle Data", vehicleData);
        const payload = {
          entry: entryObject,
          waybill: waybillObject,
          invoice: invoiceData,
          vehicles: vehicleData,
        }
        this.$emit('submit', payload);
      },
      saveEntry(){
        const invoiceData = {
          invoice_total: this.invoiceTotal,
          invoice_id: this.invoiceNumber,
          invoice_date: this.invoiceDate,
          subtotal: this.invoiceTotal,
          vendor_name: this.supplierName,
          total_tax: 0.00,
          vendor_address: '',
          purchase_order: '',
          inland: this.inlandAmount,
          insurance: this.insuranceAmount,
          otherCharges: this.otherCharges,
          currency: this.invoiceCurrency,
          rate_of_exchange: this.rateOfExchange,
          invoice_items: this.sanitizeVehicleData(),
        }
        const entryData = {
          referenceNumber: this.referenceNumber,
          waybillNumber: this.customsEntryForm.waybillNumber,
          waybillDate: this.customsEntryForm.waybillDate,
          modeOfTransport: this.customsEntryForm.modeOfTransport,
          freightType: this.customsEntryForm.freightType,
          marksAndNumbers: this.customsEntryForm.marksAndNumbers,
          countryOfOrigin: this.customsEntryForm.countryOfOrigin,
          countryOfFinalDestination: this.customsEntryForm.countryOfFinalDestination,
          countryLastProvenance: this.customsEntryForm.countryLastProvinance,
          tradingCountry: this.customsEntryForm.tradingCountry,
          kindOfPackage: this.customsEntryForm.packageType,
          numberOfPackages: this.customsEntryForm.numberOfPackages,
          totalCargoValue: this.customsEntryForm.totalCargoValue,
          freightCharge: this.customsEntryForm.freightAmount,
          insuranceAmount: this.customsEntryForm.insuranceAmount,
          otherCharges: this.customsEntryForm.otherCharges,
          rateOfExchange: this.customsEntryForm.rateOfExchange,
          netWeight: this.customsEntryForm.netWeight,
          grossWeight: this.customsEntryForm.grossWeight,
          declarant: this.customsEntryForm.declarantId,
          incoTerms: this.customsEntryForm.incoTerms,
          importer: this.customsEntryForm.importerId,
          shipper: this.customsEntryForm.exporterId,
          vessel: this.customsEntryForm.vesselId,
          portOfDischarge: this.customsEntryForm.portOfDischargeId,
          regimeType: this.customsEntryForm.regimeType,
          deposit: this.customsEntryForm.depositAmount,
          containerCharges: this.customsEntryForm.containerCharges,
          additionalCharges: this.customsEntryForm.additionalCharges,
          localFee: this.customsEntryForm.localFee,
          entryType: ENTRY_TYPES.VEHICLES,
          entryStatus: ENTRY_STATUS.STORED_AND_NOT_CLASSIFIED,
          invoiceList: [invoiceData]
        }
        console.log("Entry Data", entryData)
        this.$emit('submit', entryData);
        
      },
      pageSetup(){
        this.getRegimeTypes().then(() => {
          this.setDefaults();
        });
      },
      async fetchCountries() {
        try {
          this.countries = await FastAPI.getCountries();
        } catch (error) {
          console.error('Failed to fetch countries:', error);
        }
      },
      async getSuppliers(){
          try {
              this.suppliers = await FastAPI.getSuppliers();
          }
          catch (error) {
              console.error("Error fetching suppliers", error);
          }
      },
      setDefaults(){
        const regimeData = this.customsEntryForm.regimeTypeOptions.find(regime => regime.regimeTypeCode  === Configuration.getConfigurations().defaultRegimeType);
        this.customsEntryForm.regimeType = regimeData.regimeTypeId;
      },
      setYears() {
        const currentYear = new Date().getFullYear();
        const startYear = 1990;
        for (let year = startYear; year <= currentYear; year++) {
          this.years.push(year);
        }
      },
      async fetchNpcCodes(){
        DatabaseConnector.fetchNpcCodes().then(data => {
          this.npcCodeOptions = data;
          console.log("NPC Codes: ", data)
        });
      },
      async getRegimeTypes(){
        return FastAPI.getRegimeTypes().then((data) => {
            console.log("Fast API Regime Types: ", data);
            this.customsEntryForm.regimeTypeOptions = data;
            const regimeData = data.find(
                (regime) => regime.regimeTypeCode === Configuration.getConfigurations().defaultRegimeType
            );
            this.customsEntryForm.regimeType = regimeData.regimeTypeId;
        });
      },
      async availableNPCCodes(cpcId){
        const selectedCPC = this.availableCPCCodes.find((cpc) => cpc.cpcId === cpcId);
        return selectedCPC ? selectedCPC.npcCodes : [];
      },
      toggleDetails(index) {
        this.expandedIndex = this.expandedIndex === index ? null : index;
      },
      handleFileChange(event, fileKey) {
        this.files[fileKey] = event.target.files[0];
      },
      async readToyotaFiles() {

        // if(this.waybillFiles.length > 0){
        //   this.readWaybillModel();
        // }
        // const formData = new FormData();
        // formData.append('commercialInvoice', this.files.commercialInvoice);
        // formData.append('pdfAttachments', this.files.attachedSheet);
        // formData.append('toyotaInstructionExcel', this.files.instructionSheet);
        // formData.append('reference_number', 'RLL76568');

        // console.log("Form Data", formData)

        // SweetAlertConnector.showLoadingAlert('Uploading Files');

        // DatabaseConnector.readVehicleFiles(formData)
        //   .then((response) => {
        //     SweetAlertConnector.showSuccessAlert('Files Uploaded Successfully');
        //     console.log("Response", response);
        //     this.vehicles = this.convertVehicleData(response);
        //     for (let i = 0; i < this.vehicles.length; i++) {
        //       console.log("Vehicle Total: ", this.caclulateVehicleTotalCost(this.vehicles[i]))
        //     }

        //     this.customsEntryForm.totalCargoValue = this.invoiceTotal;

        //     console.log(this.compareInvoiceTotal());

        //   })
        //   .catch((error) => {
        //     SweetAlertConnector.showErrorAlert('Error Uploading Files');
        //     console.error('Error details:', error);
        //     if (error.response) {
        //       console.error('Error response data:', error.response.data);
        //       console.error('Error response status:', error.response.status);
        //       console.error('Error response headers:', error.response.headers);
        //     }
        //   });

          const data = [
            {
                "chassis number": "JHHCCJ3FX0K008604",
                "Full Description": "300 SERIES 3.5 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "20395",
                "ED No": "20231101",
                "model_code": "XZU650R-WKMMJ3",
                "description": "STD CAB & CHASSIS (STANDARD CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 5 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2185",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "¥ 2,834,966",
                "classification_description": "STD CAB & CHASSIS (STANDARD CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 5 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU650R-WKMMJ3 - SFX: CW S CWC650RCNT",
                "marks_and_numbers": "CHASSIS # JHHCCJ3FX0K008604"
            },
            {
                "chassis number": "JHHCCJ3F00K008627",
                "Full Description": "300 SERIES 3.5 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 - WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "20488",
                "ED No": "20231101",
                "model_code": "XZU650R-WKMMJ3",
                "description": "STD CAB & CHASSIS (STANDARD CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 5 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2185",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "¥ 2,834,966",
                "classification_description": "STD CAB & CHASSIS (STANDARD CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 5 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU650R-WKMMJ3 - SFX: CW S CWC650RCNT",
                "marks_and_numbers": "CHASSIS # JHHCCJ3F00K008627"
            },
            {
                "chassis number": "JHHCCJ3F30K008654",
                "Full Description": "300 SERIES 3.5 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "20585",
                "ED No": "20231101",
                "model_code": "XZU650R-WKMMJ3",
                "description": "STD CAB & CHASSIS (STANDARD CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 5 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2185",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "¥ 2,834,966",
                "classification_description": "STD CAB & CHASSIS (STANDARD CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 5 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU650R-WKMMJ3 - SFX: CW S CWC650RCNT",
                "marks_and_numbers": "CHASSIS # JHHCCJ3F30K008654"
            },
            {
                "chassis number": "JHHUCK1F30K054174",
                "Full Description": "300 SERIES 4.1 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "25670",
                "ED No": "20231102",
                "model_code": "XZU710R-WKMQK3",
                "description": "STD CAB & CHASSIS(WIDE CAB)2 DOORS - DIESEL - RHD 3 PERSONS 4 CYLINDER - 5 SPEED MANUAL - CONTAINER - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2440",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "¥ 3,253,604",
                "classification_description": "STD CAB & CHASSIS(WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSONS 4 CYLINDER - 5 SPEED MANUAL - CONTAINER - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU710R-WKMQK3 - SFX: CW / S CWC710RCNT",
                "marks_and_numbers": "CHASSIS # JHHUCK1F30K054174"
            },
            {
                "chassis number": "JHHYCK0F60K029949",
                "Full Description": "HINO PAYLOAD 5 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "25698",
                "ED No": "20231103",
                "model_code": "XZU720R-WKFRK3",
                "description": "LONG CAB & CHASSIS (WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 6 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2495",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "3,714,724",
                "classification_description": "LONG CAB & CHASSIS (WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 6 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU720R-WKFRK3 - SFX: CW S CWC720RCNT",
                "marks_and_numbers": "CHASSIS # JHHYCK0F60K029949"
            },
            {
                "chassis number": "JHHYCK0F10K029969",
                "Full Description": "HINO PAYLOAD 5 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "25741",
                "ED No": "20231103",
                "model_code": "XZU720R-WKFRK3",
                "description": "LONG CAB & CHASSIS (WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 6 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2495",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "3,714,724",
                "classification_description": "LONG CAB & CHASSIS (WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 6 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU720R-WKFRK3 - SFX: CW S CWC720RCNT",
                "marks_and_numbers": "CHASSIS # JHHYCK0F10K029969"
            },
            {
                "chassis number": "JHHYCK0F70K030155",
                "Full Description": "HINO PAYLOAD 5 TON",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "26049",
                "ED No": "20231103",
                "model_code": "XZU720R-WKFRK3",
                "description": "LONG CAB & CHASSIS (WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 6 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "3",
                "number_of_doors": "2",
                "quantity": 1,
                "weight": "2495",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "3,714,724",
                "classification_description": "LONG CAB & CHASSIS (WIDE CAB) 2 DOORS - DIESEL - RHD 3 PERSON S - 4 CYLINDER - 6 SPEED MANUALCNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU720R-WKFRK3 - SFX: CW S CWC720RCNT",
                "marks_and_numbers": "CHASSIS # JHHYCK0F70K030155"
            },
            {
                "chassis number": "JHHNCK1F30K020496",
                "Full Description": "HINO 300 SERIES DOUBLE CAB CHASSIS W/ PTO",
                "Broker Instruction": "Duty Paid",
                "Color Ext": "040 WHITE",
                "Color Int": "20 BLACK",
                "Engine No": "25905",
                "ED No": "20231104",
                "model_code": "XZU730R-ZKMQK3",
                "description": "DOUBLE CAB CHASSIS W/PTO 4 DOORS - DIESEL' RHD 5 PERSONS - 4 CYLINDER - 5 SPEED MANUAL - CNT - CC Rating: 4009 - Cylinders: 4",
                "engine_displacement": "4009",
                "production year:": "2023",
                "number_of_seats": "5",
                "number_of_doors": "4",
                "quantity": 1,
                "weight": "2690",
                "fuel_type": "DIESEL",
                "seat_position": "RHD",
                "new_vehicle": "True",
                "Unit Price": "¥ 3,831,568",
                "classification_description": "DOUBLE CAB CHASSIS W/PTO 4 DOORS - DIESEL' RHD 5 PERSONS - 4 CYLINDER - 5 SPEED MANUAL - CNT - CC Rating: 4009 - Cylinders: 4 - Model Year: 2024 - Production Month/Year: November / 2023 - Katashiki: XZU730R-ZKMQK3 - SFX: PT / S PTO730RCCCNT",
                "marks_and_numbers": "CHASSIS # JHHNCK1F30K020496"
            }
        ]
        this.vehicles = this.convertVehicleData(data);

        console.log("Vehicle Data Read", this.vehicles)

        this.customsEntryForm.totalCargoValue = this.invoiceTotal;

        console.log(this.compareInvoiceTotal());

      },
      convertVehicleData(jsonArray) {
        const groupedByEDNumber = [];
        const allcpcCodes = this.customsEntryForm.regimeTypeOptions.flatMap(regime => regime.cpcCodes)
        const defaultCPCId = allcpcCodes.find(cpc => cpc.code === Configuration.getConfigurations().defaultCPCCode).cpcId;
        const defaultNPCCode = this.allAvailableNPCCodes.find(
            (npc) => npc.code === "000"
        ).npcId;
        // const boncedCPCCode = allcpcCodes.find(cpc => cpc.code === Configuration.getConfigurations().boncedCPCCode).cpcId;
  
        jsonArray.forEach(item => {
          const edNumber = item['ED No'];
          const details = {
            chassisNumber: item["chassis number"],
            engineNumber: item["Engine No"],
            exteriorColor: item["Color Ext"],
            interiorColor: item["Color Int"],
            customerName: item["Customer Name"],
            customerTIN: item.customer_tin ?? "",
            brokerInstructions:  this.instructionList.find((instruction) => instruction.detail === item["Broker Instruction"])?.code || "DP",
            cpcCode: defaultCPCId,
            npcCode: defaultNPCCode,
            edNumber: edNumber,
            quantity: item["quantity"],
            grossWeight: item["gross_weight"],
            netWeight: item["net_weight"],
            engineDisplacement: item["engine_displacement"],
            manufactureYear: item.manufacture_year ?? "2000",
          };

          // const currentProductCode = item['model_code'];
          const vehicle = groupedByEDNumber.find(vehicle => vehicle.edNumber === edNumber);
          if (vehicle) {
            vehicle.quantity += parseInt(item['quantity']);
            vehicle.details.push(details);
            return;
          }
          else {
            groupedByEDNumber.push({
                vehicleCode: item['model_code'],
                description: item["description"],
                thnNumber: item["commodity_code"] === null ? "" : item["commodity_code"],
                quantity: 1,
                edNumber: item["ED No"],
                unitPrice: item["Unit Price"],
                grossWeight: item["gross_weight"] === null ? 0 : item["gross_weight"],
                netWeight: item["net_weight"] === null ? 0 : item["net_weight"],
                curbWeight: item["curb_weight"] === null ? 0 : item["curb_weight"],
                engineDisplacement: item["engine_displacement"],
                fuelType: this.fuelTypeOptions.find(fuelType => fuelType.code.toLowerCase() === 'pet')?.id || 0,
                numberOfSeats: item["number_of_seats"] === null ? 5 : item["number_of_seats"],
                numberOfDoors: item["number_of_doors"] === null ? 4 : item["number_of_doors"],
                tyreSize: item["tyre_size"] === null ? "" : item["tyre_size"],
                modelCode: item["model_code"] === null ? "" : item["model_code"],
                seatPosition: this.seatPositionOptions.find(seatPosition => seatPosition.code.toLowerCase() === 'rhd')?.id || 0,
                details: [details],
            });
          }
        });
        console.log("Vehicle Data Read", groupedByEDNumber);
        
        return groupedByEDNumber
      },

      readWaybillModel(){
        console.log("Reading Waybill Model")
        const formData = new FormData();
        this.waybillFiles.forEach((file) => {
            formData.append("files", file);
        });
        
        DatabaseConnector.readWaybillModel(formData).then((response) => {
            // this.waybillDataExtractionCompleted = true;
            // this.dataExtractionLoadingMessage();
            const waybillData = response[0]
            console.log("Waybill Data", waybillData)
            this.customsEntryForm.waybillNumber = waybillData.waybill_number
            this.customsEntryForm.numberOfPackages = Utilities.parseFloatFromString(waybillData.number_of_packages);
            this.customsEntryForm.modeOfTransport = waybillData.mode_of_transport
            const selectedImporter = Utilities.findClosestMatch(waybillData.consignee, this.customsEntryForm.importers)
            console.log("Selected Importer", selectedImporter)
            this.customsEntryForm.importerId = selectedImporter.id

            const selectedExporter = Utilities.findClosestMatch(waybillData.exporter, this.customsEntryForm.exporters)
            console.log("Selected Exporter", selectedExporter)
            this.customsEntryForm.exporterId = selectedExporter.id

            const selectedVessel = Utilities.findClosestMatch(waybillData.vessel, this.customsEntryForm.vessels)
            console.log("Selected Vessel", selectedVessel)
            this.customsEntryForm.vesselId = selectedVessel.id

            const selectedPort = Utilities.findClosestMatch(waybillData.port_of_discharge, this.customsEntryForm.ports)
            console.log("Selected Port", selectedPort)
            this.customsEntryForm.portOfDischargeId = selectedPort.id

            const shippedOnBoardString = waybillData.shipped_on_board_date;
            try{
                const parsedDate = Utilities.parseDate(shippedOnBoardString)
                this.customsEntryForm.waybillDate = Utilities.formatDateString(parsedDate);
            }
            catch(error){
                console.error("Failed to parse date", error)
            }

            if(waybillData.freight_charges !== undefined && waybillData.freight_charges !== null && waybillData.freight_charges.trim().length > 0){
                const freightCharge = Utilities.parseFreightCharges(waybillData.freight_charges);
                this.customsEntryForm.freightAmount = freightCharge;
            }

            if(waybillData.gross_weight_in_kgs !== undefined && waybillData.gross_weight_in_kgs !== null){
                this.customsEntryForm.grossWeight = Utilities.parseFloatFromString(waybillData.gross_weight_in_kgs)
            }

            if(waybillData.package_type !== undefined && waybillData.package_type !== null){
                const selectedPackageType = Utilities.findClosestMatch(waybillData.package_type, this.customsEntryForm.packageTypes, 'text')
                console.log("Selected Package Type", selectedPackageType)
                this.customsEntryForm.packageType = selectedPackageType.value
            }

        }).catch((error) => {
            console.error("Failed to read waybill model", error)
            SweetAlertConnector.showErrorAlert("Failed to read waybill model. Please try again.")
        })
      },

      commodityCodeChange(event){
        const value = event.target.innerText;
        // Remove any existing dots and non-numeric characters
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        // Format the value as XXXX.XX.XX
        const formattedValue = this.includeFullStops(sanitizedValue);
        // Update the text content of the cell
        event.target.innerText = formattedValue;
        this.moveCursorToEnd(event.target);
        // Update the item data after handling the input
      },

      includeFullStops(value){
        let formattedValue = '';
        for (let i = 0; i < value.length; i++) {
          if (i === 4 || i === 6 || i === 8) {
            formattedValue += '.';
          }
          formattedValue += value[i];
        }
        return this.truncateString(formattedValue);
      },

      getChargeData(){
        let promises = [];
        this.vehicles.forEach(vehicle => {
          const promise = DatabaseConnector.getCommodityCharges(vehicle.thnNumber.replace(/\./g, '')).then(chargesResponse => {
            console.log(chargesResponse);
            let formattedCharge = Utilities.formatCharges(chargesResponse.charges, true);
            vehicle.commodity = {
              code: vehicle.thnNumber.replace(/\./g, ''),
              id: chargesResponse.id,
              charges: formattedCharge
            }
            console.log("Vehicle Commodity", vehicle.commodity);
          }).catch(error => {
            SweetAlertConnector.closeCurrentAlert();
            SweetAlertConnector.showErrorAlert(`The following commodity code does not exist on Asycuda - ${vehicle.thnNumber}. Please update this and try again. `);
            console.error('Error details:', error);
            return Promise.reject(error);
          });

          promises.push(promise);
        })

        return Promise.all(promises);
      },

      generateAsycudaXML(){
        console.log("Generating Asycuda XML")
        SweetAlertConnector.showLoadingAlert('Generating Asycuda XML');
        const selectedPort = this.customsEntryForm.ports.find(port => port.id === this.customsEntryForm.portOfDischargeId);
        console.log("Selected Port: ", selectedPort)
        const selectedImporter = this.customsEntryForm.importers.find(importer => importer.id === this.customsEntryForm.importerId);
        const selectedExporter = this.customsEntryForm.exporters.find(exporter => exporter.id === this.customsEntryForm.exporterId);
        const selectedDeclarant = this.customsEntryForm.declarants.find(declarant => declarant.id === this.customsEntryForm.declarantId);
        const selectedCountry = this.countries.find(country => country.code === this.customsEntryForm.countryOfOrigin);
        const line_items = Utilities.formatVehicleDataXML(this.vehicles, this.availableCPCCodes, this.invoiceTotal, this.customsEntryForm.grossWeight, this.customsEntryForm.countryOfOrigin);
        const typeOfDeclaration = this.customsEntryForm.regimeType == undefined? '':  this.customsEntryForm.regimeTypeCode?.slice(0, 2);
        const general_procedural_code = this.customsEntryForm.regimeType == undefined? '':  this.customsEntryForm.regimeTypeCode?.slice(2);
        const invoiceData = {
          exworks: parseFloat(this.invoiceTotal),
          rate_of_exchange: this.rateOfExchange,
          invoice_currency: this.invoiceCurrency,
          supplementary_charges_and_deductions: {
            internal_freight: parseFloat(this.inlandAmount),
            insurance: parseFloat(this.insuranceAmount),
            other: parseFloat(this.otherCharges),
            deductions: 0.00
          },
          line_items: line_items
        };
        const requestData = {
          reference_number: this.referenceNumber,
          group_items: true,
          waybill: {
            number: this.customsEntryForm.waybillNumber,
            external_freight: this.customsEntryForm.freightAmount,
            // insurance: this.customsEntryForm.insuranceAmount == undefined? 0.00 : this.customsEntryForm.insuranceAmount,
            // other_charges: this.customsEntryForm.otherCharges == undefined? 0.00 : this.customsEntryForm.otherCharges,
            importer: {
              name: selectedImporter?.name,
              tax_identification_number: selectedImporter.taxIdentificationNumber == undefined || null? '' : selectedImporter.taxIdentificationNumber
            },
            exporter: {
              name: `${selectedExporter?.name}\n${selectedExporter?.address}`,
              tax_identification_number: selectedExporter.taxIdentificationNumber == undefined || null? '' : selectedExporter.taxIdentificationNumber
            },
            declarant: {
              name: selectedDeclarant?.name,
              tax_identification_number: selectedDeclarant.taxIdentificationNumber == undefined || null ? '' : selectedDeclarant.taxIdentificationNumber
            },
            charges: {
                external_freight: {
                    amount: this.customsEntryForm.freightAmount,
                    currency: this.customsEntryForm.freightCurrency,
                    rate_of_exchange: this.customsEntryForm.freightRateOfExchange,
                },
                insurance: {
                    amount: this.customsEntryForm.insuranceAmount,
                    currency: this.customsEntryForm.insuranceCurrency,
                    rate_of_exchange: this.customsEntryForm.insuranceRateOfExchange,
                },
                other_charges: {
                    amount: this.customsEntryForm.otherCharges,
                    currency: this.customsEntryForm.otherChargesCurrency,
                    rate_of_exchange: this.customsEntryForm.otherChargesRateOfExchange,
                },
            },
            mode_of_transport: (this.customsEntryForm.modeOfTransport === "Ocean") ? "1" : (this.customsEntryForm.modeOfTransport === "Air") ? "4" : '',
            container_flag : false,
            vessel: this.customsEntryForm.vesselName,
            country_of_origin: this.customsEntryForm.countryOfOrigin,
            country_of_destination: this.customsEntryForm.countryOfFinalDestination == undefined? '' : this.customsEntryForm.countryOfFinalDestination,
            number_of_packages: this.customsEntryForm.numberOfPackages + "",
            type_of_declaration: typeOfDeclaration,
            general_procedural_code: general_procedural_code,
            office_of_destination: selectedPort.asycudaCode,
            country_last_provinance: this.customsEntryForm.countryLastProvinance,
            trading_country: this.customsEntryForm.tradingCountry,
            country_export: this.customsEntryForm.countryOfOrigin,
            delivery_terms: this.customsEntryForm.incoTerms,
            delivery_terms_place: this.customsEntryForm.countryOfOrigin,
            place_of_terms: selectedCountry?.name || 'US',
            local_currency: 'TTD',
            office_of_entry: selectedPort.asycudaCode,
            location_of_goods: '', 
            
          },
          invoices: [invoiceData]
        }
        console.log("Request Data", requestData);
        DatabaseConnector.generateToyotaXML(requestData).then((xmlResponse) => {
          SweetAlertConnector.closeCurrentAlert();
          SweetAlertConnector.showSuccessAlert('Asycuda XML ZIP File Generated Successfully!');
          const fileURL = window.URL.createObjectURL(new Blob([xmlResponse], { type: 'application/zip' }));
            const fileLink = document.createElement('a');
            const fileName = `Asycuda-XML.zip`;
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
        }).catch(() => {
          SweetAlertConnector.showErrorAlert('Error Generating Asycuda XML');
        });
      },
      generateWorksheet(){
        console.log("Generating Worksheet")
        SweetAlertConnector.showLoadingAlert('Generating Worksheet');
        this.getChargeData().then(() => {
          const worksheetObject = Utilities.formatWorkSheetData(this.referenceNumber, 'TT', 'Ramnarine Rampersad', 'BR0285', this.customsEntryForm.regimeTypeCode, this.customsEntryForm.importerName, this.customsEntryForm.importerTaxIdentificationNumber, this.customsEntryForm.exporterName, this.customsEntryForm.exporterTaxIdentificationNumber, this.customsEntryForm.vesselName, this.customsEntryForm.portOfDischargeName, this.customsEntryForm.depositAmount, this.customsEntryForm.containerCharges, this.customsEntryForm.additionalCharges, this.customsEntryForm.localFee, this.customsEntryForm.freightAmount, this.customsEntryForm.insuranceAmount, this.customsEntryForm.otherCharges, this.customsEntryForm.freightCurrency, this.customsEntryForm.freightRateOfExchange, this.customsEntryForm.insuranceCurrency, this.customsEntryForm.insuranceRateOfExchange, this.customsEntryForm.otherChargesCurrency, this.customsEntryForm.otherChargesRateOfExchange);
          const vehicleLines = Utilities.formatVehicleDataWorksheet(this.vehicles, this.availableCPCCodes, this.customsEntryForm.countryOfOrigin);
          console.log("Vehicle Lines", vehicleLines);
          const invoiceData = {
            name: this.invoiceNumber,
            supplier: this.supplierName,
            exworks: parseFloat(this.invoiceTotal),
            invoice_currency: this.invoiceCurrency,
            cif_foreign_currency: 0.00,
            cif_local_currency: 0.00,
            rate_of_exchange: this.rateOfExchange,
            supplementary_charges_and_deductions: {
              internal_freight: parseFloat(this.inlandAmount),
              insurance: this.insuranceAmount,
              other: this.otherCharges,
              deductions: 0.00
            },
            line_items: vehicleLines
          };
          worksheetObject.invoices = [invoiceData];
          console.log("Worksheet Object", worksheetObject);
          DatabaseConnector.generateToyotaWorksheet(worksheetObject).then((worksheetResponse) => {
            SweetAlertConnector.closeCurrentAlert();
            SweetAlertConnector.showSuccessAlert('Worksheet Generated Successfully!');
            const fileURL = window.URL.createObjectURL(new Blob([worksheetResponse], { type: 'application/zip' }));
            const fileLink = document.createElement('a');
            const fileName = `Worksheet.zip`;
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          }).catch(() => {
            SweetAlertConnector.showErrorAlert('Error Generating Worksheet');
          });
        }).catch(() => {
          SweetAlertConnector.showErrorAlert('Error Generating Worksheet');
        });
      },
      truncateString(str) {
        if (str.length > 14) {
          return str.substring(0, 14);
        }
        return str;
      },
      moveCursorToEnd(element) {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false); // Collapse range to end
        selection.removeAllRanges();
        selection.addRange(range);
      },
    },
   
    
  };
  </script>
  
  <style>
  table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown ul li:hover {
  background-color: #f0f0f0;
}
.vehicleAttchments {
  margin-bottom: 20px;
}
.vehicle-form{
  text-align: left;
    padding: 20px;
}
.valid-commodity-code {
    color: #09ba09;
    font-weight: bold;
}

.invalid-commodity-code {
    color: red;
    font-weight: bold;
}
  </style>
  