<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <!-- Section 7 -->
          <label><strong>7 (a) Are the buyer and seller RELATED?</strong></label>
          <div class="form-check">
            <input type="radio" id="related-yes" value="yes" v-model="localData.relatedParties" @change="updateSection" />
            <label for="related-yes">YES</label>
            <input type="radio" id="related-no" value="no" v-model="localData.relatedParties" @change="updateSection" />
            <label for="related-no">NO</label>
          </div>
          <div v-if="localData.relatedParties === 'yes'">
            <textarea class="form-control" id="related-notes" placeholder="If 'YES', give details." v-model="localData.relatedDetails" rows="3" @input="updateSection"></textarea>
          </div>
          <label for="influencePrice"><strong>7 (b) Did the relationship INFLUENCE the price of the imported goods?</strong></label>
          <div class="form-check">
            <input type="radio" id="influence-price-yes" value="yes" v-model="localData.influencePrice" @change="updateSection" />
            <label for="influence-price-yes">YES</label>
            <input type="radio" id="influence-price-no" value="no" v-model="localData.influencePrice" @change="updateSection" />
            <label for="influence-price-no">NO</label>
          </div>
          <div v-if="localData.influencePrice === 'yes'">
            <textarea class="form-control" id="influence-price-details" placeholder="If 'YES', give details." v-model="localData.influencePriceDetails" rows="3" @input="updateSection"></textarea>
          </div>
          <label for="transactionValueApproximate"><strong>7 (c) Does the transaction value of the imported goods closely approximate to a value mentioned in Paragraph 3(2)(b)?</strong></label>
          <div class="form-check">
            <input type="radio" id="transaction-value-yes" value="yes" v-model="localData.transactionValueApproximate" @change="updateSection" />
            <label for="transaction-value-yes">YES</label>
            <input type="radio" id="transaction-value-no" value="no" v-model="localData.transactionValueApproximate" @change="updateSection" />
            <label for="transaction-value-no">NO</label>
          </div>
          <div v-if="localData.transactionValueApproximate === 'yes'">
            <textarea class="form-control" id="transaction-value-details" placeholder="If 'YES', give details." v-model="localData.transactionValueDetails" rows="3" @input="updateSection"></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- Section 8 -->
        <div class="form-group">
          <label for="restrictions"><strong>8 (a) Are there any RESTRICTIONS to the disposition or use of the goods?</strong></label>
          <div class="form-check">
            <input type="radio" id="restrictions-yes" value="yes" v-model="localData.restrictions" @change="updateSection" />
            <label for="restrictions-yes">YES</label>
            <input type="radio" id="restrictions-no" value="no" v-model="localData.restrictions" @change="updateSection" />
            <label for="restrictions-no">NO</label>
          </div>
          <div v-if="localData.restrictions === 'yes'">
            <textarea class="form-control" id="restrictions-details" placeholder="Specify the nature of the restrictions." v-model="localData.restrictionsDetails" rows="3" @input="updateSection"></textarea>
          </div>
          <label for="conditions"><strong>8 (b) Is the sale or price subject to some CONDITION or CONSIDERATION?</strong></label>
          <div class="form-check">
            <input type="radio" id="conditions-yes" value="yes" v-model="localData.conditions" @change="updateSection" />
            <label for="conditions-yes">YES</label>
            <input type="radio" id="conditions-no" value="no" v-model="localData.conditions" @change="updateSection" />
            <label for="conditions-no">NO</label>
          </div>
          <div v-if="localData.conditions === 'yes'">
            <textarea class="form-control" id="conditions-details" placeholder="Specify the nature of the conditions or considerations." v-model="localData.conditionsDetails" rows="3" @input="updateSection"></textarea>
          </div>
          <!-- Section 9 -->
          <label for="royalties"><strong>9 (a) Are any ROYALTIES AND LICENCE FEES related to the imported goods payable?</strong></label>
          <div class="form-check">
            <input type="radio" id="royalties-yes" value="yes" v-model="localData.royalties" @change="updateSection" />
            <label for="royalties-yes">YES</label>
            <input type="radio" id="royalties-no" value="no" v-model="localData.royalties" @change="updateSection" />
            <label for="royalties-no">NO</label>
          </div>
          <div v-if="localData.royalties === 'yes'">
            <textarea class="form-control" id="royalties-details" placeholder="Specify conditions and, if possible, amounts." v-model="localData.royaltiesDetails" rows="3" @input="updateSection"></textarea>
          </div>
          <label for="royalties"><strong>9 (b) Is the sale subject to an arrangement under which part of the proceeds of any subsequent RESALE, DISPOSAL or USE accures directy or indirectly to the seller?</strong></label>
          <div class="form-check">
            <input type="radio" id="resale-yes" value="yes" v-model="localData.resale_disposal_boolean" @change="updateSection" />
            <label for="resale-yes">YES</label>
            <input type="radio" id="resale-no" value="no" v-model="localData.resale_disposal_boolean" @change="updateSection" />
            <label for="resale-no">NO</label>
          </div>
          <div v-if="localData.resale_disposal_boolean === 'yes'">
            <textarea class="form-control" id="resale-details" placeholder="Please Specify conditions" v-model="localData.resaleDetails" rows="3" @input="updateSection"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="signatory_name"><strong>Name of Signatory</strong></label>
          <input type="text" class="form-control" id="signatory_name" v-model="localData.signatory_name" @input="updateSection" disabled="true"/>
        </div>
        <div class="form-group">
          <label for="date_signed"><strong>Date Signed</strong></label>
          <input type="date" class="form-control" id="date_signed" v-model="localData.date_signed" @input="updateSection" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="status_of_signatory"><strong>Status of Signatory</strong></label>
          <input type="text" class="form-control" id="status_of_signatory" v-model="localData.status_of_signatory" @input="updateSection" disabled="true"/>
        </div>
        <div class="form-group">
          <label for="signatory_phone_number"><strong>Signatory Phone Number</strong></label>
          <input type="text" class="form-control" id="signatory_phone_number" v-model="localData.signatory_phone_number" @input="updateSection" disabled="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['customsDeclarationSectionTwo', 'customsDeclarationSectionOne'],
  name: 'CustomsDeclarationSectionTwo',
  data() {
    return {
      localData: this.customsDeclarationSectionTwo
    };
  },
  methods: {
    updateSection() {
      this.$emit('update-section-two', this.localData);
      console.log(this.localData);
    }
  },
  watch: {
    customsDeclarationSectionOne: {
      deep: true,
      handler(newVal) {
        const declarantId = newVal.declarantId;
        const declarant = newVal.declarants?.find(declarant => declarant.id === declarantId);
        this.localData.status_of_signatory = declarant?.status;
        this.localData.signatory_name = declarant?.name;
        this.localData.signatory_phone_number = declarant?.phoneContact;
      }
      
    },
    customsDeclarationSectionTwo: {
      deep: true,
      handler(newVal) {
        this.localData = { ...newVal };
      }
    }
  }
};
</script>

<style scoped>
.customs-declaration-section-two {
  /* Your styles here */
}
.form-group {
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check label {
  margin-right: 1rem;
}
</style>
