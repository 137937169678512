<template>
    <div class="suppliers-page">
      <h1>Suppliers List</h1>
      <form class="my-form-container" @submit.prevent="addSupplier">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="supplierName"><strong>Name</strong></label>
              <input type="text" class="form-control" id="supplierName" placeholder="Please Enter Supplier Name" v-model="supplierName" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="supplierAddressLine1"><strong>Address Line 1</strong></label>
              <input type="text" class="form-control" id="supplierAddressLine1" placeholder="Please Enter Address Line 1" v-model="supplierAddressLine1" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="supplierAddressLine2"><strong>Address Line 2</strong></label>
              <input type="text" class="form-control" id="supplierAddressLine2" placeholder="Please Enter Address Line 2" v-model="supplierAddressLine2" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="supplierCity"><strong>City</strong></label>
              <input type="text" class="form-control" id="supplierCity" placeholder="Please Enter City" v-model="supplierCity" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="supplierCountry"><strong>Country</strong></label>
              <v-select :options="countries" v-model="supplierCountry" label="name" :searchable="true" :reduce="country => country.id" :filterable="true" placeholder="Select a Country"></v-select>
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label for="tinNumber"><strong>TIN Number</strong></label>
              <input type="text" class="form-control" id="tinNumber" placeholder="TIN Number" v-model="tinNumber" />
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <p><strong></strong></p>
              <button class="btn btn-primary" type="submit">Add Supplier</button>
             </div>
          </div>
        </div> 
      </form>
      <table class="table table-striped">
        <thead class="sticky-header">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Address</th>
            <th scope="col">Country</th>
            <th scope="col">TIN Number</th>
            <th scope="col">Expand</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(supplier, index) in suppliers" :key="index">
            <tr >
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ supplier.name }}</td>
              <td>{{ supplier.address }}</td>
              <td>{{ getCountryName(supplier.country) }}</td>
              <td>{{ supplier.taxIdentificationNumber }}</td>
              <td>
                <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                    <span v-if="expandedIndex === index">
                        <i class="fas fa-chevron-up" style="color: white"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-chevron-down" style="color: white"></i>
                    </span>
                </button>
              </td>
            </tr>
            <tr v-if="expandedIndex === index">
              <td colspan="6">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="supplierName"><strong>Name</strong></label>
                      <input type="text" class="form-control" id="supplierName" placeholder="Please Enter Supplier Name" v-model="supplier.name" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="tinNumber"><strong>Tax Identification Number</strong></label>
                      <input type="text" class="form-control" id="tinNumber" placeholder="Please Enter TIN Number" v-model="supplier.taxIdentificationNumber" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="supplierAddress"><strong>Address</strong></label>
                      <textarea type="text" class="form-control" id="supplierAddressLine1" placeholder="Please Enter Address" v-model="supplier.address" rows="4"></textarea>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="supplierCountry"><strong>Country</strong></label>
                      <v-select :options="countries"
                      v-model="supplier.country"
                      label="name"
                      :searchable="true"
                      :filterable="true"
                      :reduce="option => option.id"
                      ></v-select>
                    </div>
                  </div>
                </div>  
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <button class="btn btn-primary edit-form-button" @click="updateSupplier(supplier)">Update Supplier</button>
                      <button class="btn btn-danger edit-form-button" @click="deleteSupplier(supplier)">Delete Supplier</button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import * as SweetAlertConnector from '@/composables/SweetAlertConnector';
  import * as FastAPI from '@/composables/FastAPI';
  
  export default {
    name: 'SuppliersPage',
    data() {
      return {
        suppliers: [],
        countries: [],
        supplierName: '',
        supplierAddressLine1: '',
        supplierAddressLine2: '',
        supplierCity: '',
        supplierCountry: '',
        tinNumber: '',
        expandedIndex: null,
      };
    },
    methods: {
      toggleDetails(index) {
        this.expandedIndex = this.expandedIndex === index ? null : index;
      },
      async getSuppliers() {
        this.suppliers = await FastAPI.getSuppliers();
        console.log(this.suppliers);
      },
      async updateSupplier(supplier){
        const updatedSupplier = {
            ...supplier, // Copy all other properties
            tax_identification_number: supplier.taxIdentificationNumber // Rename the key
        };
        console.log("Updating Supplier", updatedSupplier);
        SweetAlertConnector.showLoadingAlert('Updating Supplier');
        FastAPI.updatePartner(supplier.partner_id, updatedSupplier).then(() => {
          SweetAlertConnector.showSuccessAlert('Supplier Updated Successfully');
          this.getSuppliers();
        }).catch((error) => {
          console.error("Failed to update Supplier", error);
          SweetAlertConnector.showErrorAlert('Failed to Update Supplier', error);
        });
      },
      async deleteSupplier(supplier){
        SweetAlertConnector.showLoadingAlert('Deleting Supplier');
        FastAPI.deletePartner(supplier.partner_id).then(() => {
          SweetAlertConnector.showSuccessAlert('Supplier Deleted Successfully');
          this.getSuppliers();
        }).catch((error) => {
          SweetAlertConnector.showErrorAlert('Failed to Delete Supplier', error);
        });
      },
      async addSupplier() {
        const newSupplier = {
          name: this.supplierName,
          address: `${this.supplierAddressLine1}\n${this.supplierAddressLine2}\n${this.supplierCity}`,
          country: this.supplierCountry,
          phone_contact: '',
          tax_identification_number: this.tinNumber,
        };
        console.log("New Supplier", newSupplier);
        SweetAlertConnector.showLoadingAlert('Adding Supplier');
        try{
          const newPartner = await FastAPI.createPartner(newSupplier);
          console.log("New Partner. Setting to supplier", newPartner);
          const supplier = {
            partner_id: newPartner.id
          };
          console.log("Supplier", supplier);
          await FastAPI.addSupplierPartner(supplier);
          SweetAlertConnector.showSuccessAlert('Supplier Added Successfully');
          this.supplierName = '';
          this.supplierAddressLine1 = '';
          this.supplierAddressLine2 = '';
          this.supplierCity = '';
          this.supplierCountry = '';
          this.tinNumber = '';
          this.getSuppliers();
        }
        catch(error){
          console.error("Failed to add Supplier", error);
          SweetAlertConnector.showErrorAlert('Failed to Add Supplier', error);
        }
        
      },
      async getCountries() {
        this.countries = await FastAPI.getCountries();
        console.log("Countries", this.countries);
        },
    getCountryName(id){
      const country = this.countries.find(country => country.id === id);
      return country ? country.name : '';
    }
  },
  mounted() {
    this.getSuppliers();
    this.getCountries();
    // Any code you want to run after the component is mounted
  }
}
</script>

<style scoped>
.buyers-page {
  padding: 20px;
  text-align: center;
}

.customs-declaration-section-two {
    /* Your styles here */
  }
  .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
</style>

  