<template>
    <div class="landing-page">
      <header class="header">
        <div class="logo">
          <span>MAWI</span>
        </div>
        <nav class="nav">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/register">Get Started</router-link></li>
            <li><a href="https://valiertechnologies.com" target="_blank">About Us</a></li>
          </ul>
        </nav>
        <button class="signup-btn" @click="gotToLogin">Sign In</button>
      </header>
      <div class="main-content">
        <h1>MAWI</h1>
        <p>
          Automating Customs Documentation, Classification, Licensing, and Much more..
        </p>
        <button class="learn-more-btn" @click="goToRegister">Sign up with us today</button>
      </div>
      <div class="shapes">
        <div class="shape triangle"></div>
        <div class="shape circle"></div>
        <div class="shape square"></div>
        <div class="shape lines"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
    methods: {
      gotToLogin() {
        this.$router.push('/login');
      },
      goToRegister() {
        this.$router.push('/register');
      },
    },
  };
  </script>
  
  <style scoped>
  .landing-page {
    background: linear-gradient(135deg, #3b82f6, #60a5fa);
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  .logo {
    font-size: 1.5em;
    font-weight: bold;
  }
  .nav ul {
    display: flex;
    list-style: none;
    gap: 20px;
  }
  .nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 1em;
  }
  .signup-btn {
    background: none;
    border: 2px solid white;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }
  .main-content {
    text-align: center;
    margin-top: 100px;
  }
  .main-content h1 {
    font-size: 4em;
    margin-bottom: 20px;
  }
  .main-content p {
    font-size: 1.2em;
    margin-bottom: 40px;
    max-width: 500px;
  }
  .learn-more-btn {
    background-color: white;
    color: #3b82f6;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    border: none;
  }
  .shapes {
    position: absolute;
    top: 30%;
    right: 10%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .shape {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, #ff7ce5, #ffa45b);
    opacity: 0.8;
  }
  .triangle {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  .circle {
    border-radius: 50%;
  }
  .square {
    background: linear-gradient(135deg, #60a5fa, #3b82f6);
  }
  .lines {
    width: 150px;
    height: 5px;
    background-color: white;
  }
  </style>
  