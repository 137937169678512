<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="netPrice"><strong>11(a) Net price in CURRENCY OF INVOICE</strong></label>
          <input type="number" class="form-control" id="netPrice" v-model="localData.netPrice" @input="updateSection" disabled="true">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="invoiceCurrency" class="form-label"><strong>Invoice Currency</strong></label>
          <select class="form-select" v-model="localData.invoiceCurrency" @change="updateField('invoiceCurrency', $event.target.value)" disabled="true">
            <option value="" disabled>Select a Currency</option>
            <option v-for="currency in localData.currencyList" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exchangeRate"><strong>Rate of exchange</strong></label>
          <input type="number" class="form-control" id="exchangeRate" v-model="localData.exchangeRate" @input="updateSection" disabled="true"/>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- A: Basics of calculation -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="indirectPayments"><strong>11(b) Indirect payments - see box 8 (b)</strong></label>
          <input type="number" class="form-control" id="indirectPayments" v-model="localData.indirectPayments" @input="updateSection" disabled="true"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="totalNationalCurrency"><strong>12. TOTAL A in National Currency ({{userDetails.mawi_business.country.currency.currency_code}})</strong></label>
          <input type="number" class="form-control" readonly id="totalNationalCurrency" v-model="localData.totalNationalCurrency" @input="updateSection" disabled="true"/>
        </div>
      </div>
    </div>
    

    <!-- B: Additions -->
    <div class="row form-group">
      
        <label for="costsIncurred"><strong>13. Costs incurred by the buyer</strong></label>
        <div class="col-md-4">
          <input type="number" class="form-control mb-2" id="commissions" v-model="localData.commissions" placeholder="commissions, except buying commissions" @input="updateSection" />
        </div>
        <div class="col-md-4">
          <input type="number" class="form-control mb-2" id="brokerage" v-model="localData.brokerage" placeholder="brokerage" @input="updateSection" />
        </div>
        <div class="col-md-4">
          <input type="number" class="form-control" id="containersPacking" v-model="localData.containersPacking" placeholder="containers and packing" @input="updateSection" />
        </div>
    </div>

    <div class="row ">
      <label for="goodsServices"><strong>14. Goods and services supplied by the buyer</strong></label>
      <div class="col-md-6 ">
        <input type="number" class="form-control mb-2" id="materials" v-model="localData.materials" placeholder="materials, component, parts incorporated in the good" @input="updateSection" />
        <input type="number" class="form-control mb-2" id="tools" v-model="localData.tools" placeholder="tools, dies, moulds used in the production" @input="updateSection" />
      </div>
      <div class="col-md-6 form-group">
        <input type="number" class="form-control mb-2" id="materialsConsumed" v-model="localData.materialsConsumed" placeholder="materials consumed in the production" @input="updateSection" />
        <input type="number" class="form-control" id="engineering" v-model="localData.engineering" placeholder="engineering, development, artwork, design work" @input="updateSection" />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-6">
        <label for="royalties"><strong>15. Royalties and licence fees</strong></label>
        <input type="number" class="form-control" id="royalties" v-model="localData.royalties" @input="updateSection" />
      </div>
      <div class="col-md-6">
        <label for="proceedsResale"><strong>16. Proceeds of any subsequent resale, disposal or use accruing to the seller</strong></label>
        <input type="number" class="form-control" id="proceedsResale" v-model="localData.proceedsResale" @input="updateSection" />
      </div>
    </div>


    <div class="row">
      <label for="costOfDelivery"><strong>17. Cost of delivery to {{userDetails.mawi_business.country.name}}</strong></label>
      <div class="col-md-6">
        <div class="form-group">
          <label for="transport"><strong>Transport</strong></label>
          <input type="number" class="form-control mb-2" id="transport" :disabled="localData.transportIncluded" v-model="localData.transport" placeholder="transport" @input="updateSection" />
        </div>
        <div class="form-group">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" v-model="localData.transportIncluded" id="includeTransportInTotal" @change="handleTransportCheckboxChange">
            <label class="form-check-label" for="includeTransportInTotal">Transportation cost included in the invoice total</label>
          </div>
        </div>
        <div class="form-group">
          <label for="loadingHandling"><strong>Loading and handling charges</strong></label>
          <input type="number" class="form-control mb-2" id="loadingHandling" v-model="localData.loadingHandling" placeholder="loading and handling charges" @input="updateSection" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="insurance"><strong>Insurance</strong></label>
          <input type="number" class="form-control" id="insurance" :disabled="localData.insuranceIncluded" v-model="localData.insurance" placeholder="insurance" @input="updateSection" />
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" v-model="localData.insuranceIncluded" id="includeInsuranceInTotal" @change="handleInsuranceCheckboxChange">
            <label class="form-check-label" for="includeInsuranceInTotal">Insurance cost included in the invoice total</label>
          </div>
        <div class="form-group">
          <label for="totalB"><strong>18. TOTAL B</strong></label>
          <input type="number" class="form-control" readonly id="totalB" v-model="localData.totalB" @input="updateSection" disabled="true"/>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import * as FastAPI from '@/composables/FastAPI';

export default {
  props: ['customsDeclarationSectionThree'],
  name: 'CustomsDeclarationSectionThree',
  
  mounted() {
    this.getUser();
  },
  data() {
    return {
      localData: this.customsDeclarationSectionThree,
      userDetails: {'mawi_business':{'country':{"currency":""}}},
      currencies : []
    };
  },
  methods: {
    async getUser(){
            try{
                this.userDetails = await FastAPI.getUserDetails();
            }
            catch(error){
                console.log("Error getting all details", error)
            }
        },
    handleTransportCheckboxChange(event) {
      this.localData.transportIncluded = event.target.checked;
      if(this.localData.transportIncluded)
        this.localData.transport = 0;
      this.updateSection();  // Call your update method if necessary
    },
    handleInsuranceCheckboxChange(event) {
      this.localData.insuranceIncluded = event.target.checked;
      console.log(this.localData.insuranceIncluded)
      console.log("Inside", this.localData)
      if(this.localData.insuranceIncluded)
        this.localData.insurance = 0;
      this.updateSection();  // Call your update method if necessary
    },
    updateField(field, value) {
      this.localData[field] = value;
      this.updateSection();  // Call your update method if necessary
    },
    updateSection() {
      this.$emit('update-section-three', this.localData);
      console.log(this.localData);
    }
  },
  watch: {
    customsDeclarationSectionThree: {
      deep: true,
      handler(newVal) {
        // console.log(this.localData.transportIncluded, newVal.transportIncluded)
        
        this.localData = {...newVal};
        let totalA = (parseFloat(this.localData.netPrice) + parseFloat(this.localData.indirectPayments)) * parseFloat(this.localData.exchangeRate);
        let totalB = parseFloat(this.localData.transport) + parseFloat(this.localData.loadingHandling) + parseFloat(this.localData.insurance);
        console.log(totalA, totalB);
        this.localData.totalNationalCurrency = parseFloat(totalA).toFixed(2);
        this.localData.totalB = parseFloat(totalB).toFixed(2);
      }
    }
  }
};
</script>

<style scoped>
.customs-declaration-section-two {
  /* Your styles here */
}
.form-group {
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check label {
  margin-right: 1rem;
}
</style>
