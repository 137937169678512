<template>
  <div class="vessels-page">
    <h1>Vessels List</h1>
    <form class="my-form-container" @submit.prevent="createVessel">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="vesselName"><strong>Vessel Name</strong></label>
            <input type="text" class="form-control" id="vesselName" placeholder="Please Enter Vessel Name" v-model="vesselName" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="imoNumber"><strong>IMO Number</strong></label>
            <input type="text" class="form-control" id="imoNumber" placeholder="Please Enter IMO Number" v-model="imoNumber" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="country"><strong>Country</strong></label>
            <v-select 
              :options="countries" 
              v-model="selectedCountry" 
              label="name" 
              :searchable="true"  
              :filterable="true" 
              :reduce="country => country.id"
              placeholder="Select a Country"
            >
            </v-select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <p><strong></strong></p>
            <button class="btn btn-primary" type="submit">Add Vessel</button>
          </div>
        </div>
      </div>
    </form>
    <table class="table table-striped">
      <thead class="sticky-header">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Vessel Name</th>
          <th scope="col">IMO Number </th>
          <th scope="col">Gross Tonnage</th>
          <th scope="col">Length Overall</th>
          <th scope="col">Registered Country</th>
          <th scope="col">Expand</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(vessel, index) in vessels" :key="index">
          <tr >
            <th scope="row">{{ index + 1 }}</th>
            <td>{{vessel.name}}</td>
            <td>{{vessel.imo_number}}</td>
            <td>{{vessel.gross_tonnage || "0.0"}}</td>
            <td>{{vessel.length_overall || "0.0" }}</td>
            <td>{{vessel.registered_country?.name || "Not Set"}}</td>
            <td>
              <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                      <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                      <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
              </button>
            </td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="7">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="vesselName"><strong>Vessel Name</strong></label>
                    <input type="text" class="form-control" id="vesselName" placeholder="Please Enter Vessel Name" v-model="vessel.name" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="imoNumber"><strong>IMO Number</strong></label>
                    <input type="text" class="form-control" id="imoNumber" placeholder="Please Enter IMO Number" v-model="vessel.imo_number" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="grossTonnage"><strong>Gross Tonnage</strong></label>
                    <input type="text" class="form-control" id="grossTonnage" placeholder="Please Enter Gross Tonnage" v-model="vessel.gross_tonnage" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="lengthOverall"><strong>Length Overall</strong></label>
                    <input type="text" class="form-control" id="lengthOverall" placeholder="Please Enter Length Overall" v-model="vessel.length_overall" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="registeredCountry"><strong>Registered Country</strong></label>
                    <v-select :options="countries" v-model="vessel.registered_country_id" label="name" :searchable="true" :reduce="country => country.id"  :filterable="true" placeholder="Select a Country of Origin"></v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <button class="btn btn-primary edit-form-button" type="submit" @click="updateVessel(vessel)">Update Vessel</button>
                    <button class="btn btn-danger edit-form-button" type="submit" @click="deleteVessel(vessel)">Delete Vessel</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>    
  </div> 
</template>
  
  <script>
    import * as SweetAlertConnector from '@/composables/SweetAlertConnector';
    import * as FastAPI from '@/composables/FastAPI';
  export default {
    
    name: 'VesselsPage',
    data(){
        return {
          vessels: [],
          countries: [],
          expandedIndex: null,
          vesselName: '',
          imoNumber: '',
          selectedCountry: null,
        }
    },
    mounted() {
        this.getVessels();
        this.getCountries();
    },
    methods: {
        toggleDetails(index) {
          this.expandedIndex = this.expandedIndex === index ? null : index;
        },
        async updateVessel(vessel){
          SweetAlertConnector.showLoadingAlert('Updating Vessel');
          console.log(vessel);
          FastAPI.updateVessel(vessel.id, vessel).then(() => {
            SweetAlertConnector.showSuccessAlert('Vessel Updated Successfully');
            this.getVessels();
          }).catch((error) => {
            SweetAlertConnector.showErrorAlert('Failed to Update Vessel', error);
          });
        },
        async deleteVessel(vessel){
          vessel.active = 0;
          SweetAlertConnector.showLoadingAlert('Deleting Vessel');
          FastAPI.deleteVessel(vessel.id).then(() => {
            SweetAlertConnector.showSuccessAlert('Vessel Deleted Successfully');
            this.getVessels();
          }).catch((error) => {
            SweetAlertConnector.showErrorAlert('Failed to Delete Vessel', error);
          });
        },
        async getVessels() {
            this.vessels = await FastAPI.getVessels();
            console.log(this.vessels);
        },
        async getCountries() {
          try {
                this.countries = await FastAPI.getCountries();
                console.log(this.countries);
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        },
        async createVessel(){
          const newVessel = {
            name: this.vesselName,
            imo_number: this.imoNumber,
            registered_country_id:  this.selectedCountry,
            type: "",
            gross_tonnage: 0,
            length_overall: 0
          }
          try {
            SweetAlertConnector.showLoadingAlert('Adding Vessel');
            await FastAPI.addVessel(newVessel);
            SweetAlertConnector.showSuccessAlert('Vessel Added Successfully');
          }
          catch (error) {
            console.error('Error adding vessel', error);
            SweetAlertConnector.showErrorAlert('Failed to Add Vessel', error);
          }

        }
    },
  }
  </script>
  
  <style scoped>

  .vessels-page {
    padding: 20px;
    text-align: center;
  }
 .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
  </style>
  