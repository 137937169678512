<template>
    <div>
      <VehicleEntry @submit="handleCreate" />
    </div>
  </template>
  
  <script>
  import VehicleEntry from './VehicleEntry.vue';
  // import * as DatabaseConnector from '@/composables/DatabaseConnector';
  import * as FastAPI from '@/composables/FastAPI';
  import * as SweetAlertConnector from '../composables/SweetAlertConnector';
  
  export default {
    components: { VehicleEntry },
    methods: {
      async saveWaybillFastAPI(waybillObject){
        console.log("Saving Waybill", waybillObject);
        return FastAPI.saveWaybill(waybillObject).then((response) => {
            console.log("Waybill Response: ", response);
            const waybillId = response.id;
            return waybillId;
        }).catch((error) => {
            console.error("Failed to save waybill:", error);
            SweetAlertConnector.showErrorAlert("Failed to save waybill");
            throw error;
        });
      },
      async saveEntryFastAPI(entryObject){
        console.log("Saving Entry", entryObject);
        return FastAPI.createCustomsEntry(entryObject).then((response) => {
            console.log("Entry Response: ", response);
            const entryId = response.id;
            return entryId;
        }).catch((error) => {
            console.error("Failed to save entry:", error);
            SweetAlertConnector.showErrorAlert("Failed to save entry");
            throw error;
        });
      },
      async saveInvoiceFastAPI(invoiceObject){
        console.log("Saving Invoice", invoiceObject);
        return FastAPI.createCommercialInvoices(invoiceObject).then((response) => {
            console.log("Invoice Response: ", response);
            const invoiceList = response.data;
            return invoiceList;
        }).catch((error) => {
            console.error("Failed to save invoice:", error);
            SweetAlertConnector.showErrorAlert("Failed to save invoice");
            throw error;
        });
      },
      async saveVehicles(vehicleList){
        console.log("Saving Vehicles", vehicleList);
        const promises = vehicleList.map(async (vehicle) => {
          return FastAPI.createVehicle(vehicle).then((response) => {
            console.log("Vehicle Response: ", response);
            return response;
          }).catch((error) => {
            console.error("Failed to save vehicle:", error);
            SweetAlertConnector.showErrorAlert("Failed to save vehicle");
            throw error;
          });
        });
        return Promise.all(promises);
      },
      async handleCreate(entryData) {
        try {
          console.log("Saving Entry", entryData);
          SweetAlertConnector.showLoadingAlert('Saving your Entry...');
          const waybillId = await this.saveWaybillFastAPI(entryData.waybill);
          entryData.entry.waybill_id = waybillId;
          const entryId = await this.saveEntryFastAPI(entryData.entry);
          entryData.invoice.customs_entry_id = entryId;
          console.log("Entry created successfully:", entryId);
          const invoiceList = await  this.saveInvoiceFastAPI([entryData.invoice]);
          const invoice = invoiceList[0];
          console.log("Invoice created successfully:", invoiceList);
          
          let vehicleList = entryData.vehicles;
          for (let i = 0; i < invoice.invoice_lines.length; i++) {
            const invoiceLine = invoice.invoice_lines[i];
            vehicleList[i].invoice_line_id = invoiceLine.id;
          }
          const vehicleResponse = await this.saveVehicles(vehicleList);
          console.log("Vehicles created successfully:", vehicleResponse);
          SweetAlertConnector.closeCurrentAlert();
          SweetAlertConnector.showSuccessAlert('Entry created successfully');
          this.$router.push({ name: 'VehicleEntryEditPage', params: { entryId } });
        } catch (error) {
          console.error('Failed to create the invoice:', error);
          SweetAlertConnector.closeCurrentAlert();
          SweetAlertConnector.showErrorAlert('Failed to create the Entry. Please try again.');
        }
      },
    },
  };
  </script>
  