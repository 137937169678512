<template>
    <div class="classification-report-page">
      <h1>Classification Accuracy Details</h1>
      <!-- Search field -->
      <input type="text" v-model="searchTerm" placeholder="Search classification details" />
  
      <table class="table table-bordered table-striped">
        <thead class="sticky-header">
          <tr>
            <th>#</th>
            <th>MAWI Code</th>
            <th>Human Code</th>
            <th>Description</th>
            <th>Supplier Name</th>
            <th>Reference #</th>
            <th>Open Entry</th>
            <th>View Invoice</th>
            <th>Expand</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(entry, index) in classificationData" :key="index">
            <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ entry.llm_recommended_classification_code }}</td>
            <td>{{ entry.approved_classification_code }}</td>
            <td>{{ entry.description }}</td>
            <td>{{ entry.supplier_name }}</td>
            <td>{{ entry.entry_number }}</td>
            <td>
                <button class="btn btn-success" @click="openEntry(entry.entry_id)">
                    <i class="fa fa-folder-open"></i>
                </button>
            </td>
            <td>
              <button type="button" class="btn btn-success" @click="openInvoice(entry.file_url)">
                <i class="fa fa-file-invoice"></i>
              </button>
            </td>
            <td>
                <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                    <span v-if="expandedIndex === index">
                        <i class="fas fa-chevron-up" style="color: white"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-chevron-down" style="color: white"></i>
                    </span>
                </button>
            </td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="9">
              <table>
                <thead>
                  <th></th>
                  <th>Commodity Code</th>
                  <th>Commodity Code Description</th>
                  <th>VAT</th>
                  <th>DUTY</th>
                </thead>
                <tr>
                  <td>MAWI Code</td>
                  <td> {{ entry.llm_recommended_classification_code }}</td>
                  <td> {{ entry.llm_recommended_classification_description }}</td>
                  <td> {{ entry.llm_recommended_classification_code_vat }}%</td>
                  <td> {{ entry.llm_recommended_classification_code_duty }}%</td>
                </tr>
                <tr>
                  <td>Human Code</td>
                  <td> {{ entry.approved_classification_code }}</td>
                  <td> {{ entry.approved_classification_description }}</td>
                  <td> {{ entry.approved_classification_code_vat }}%</td>
                  <td> {{ entry.approved_classification_code_duty }}%</td>
                </tr>
              </table>
            </td>
          </tr>
          </template>
          
        </tbody>
      </table>
      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as DatabaseConnector from "@/composables/DatabaseConnector";
  
  export default {
    name: "ClassificationAccuracyReport",
    data() {
      return {
        classificationData: [], // To hold the fetched entries
        error: null, // To handle errors
        searchTerm: '', // To hold the search term
        expandedIndex: null,
      };
    },
    created() {
      this.fetchClassificationDetails(); // Fetch classification details when the page is created
    },
    methods: {
        toggleDetails(index) {
          this.expandedIndex = this.expandedIndex === index ? null : index;
        },
        openInvoice(url){
            const newWindow = window.open('', '_blank', 'width=800,height=600');
            if (newWindow) {
                newWindow.document.write(
                `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
                );
                newWindow.document.title = 'Document View';
            } else {
                console.error('Failed to open new window.');
            }
        },
      async fetchClassificationDetails() {
        try {
            const invoiceLineIds = this.$route.query.invoiceLineIds;
            console.log('Invoice Line IDs:', invoiceLineIds);
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}classification-data`, {
            params: {
                invoiceLineIds: this.$route.query.invoiceLineIds
            }
            });
            console.log('Classification data:', response.data);
            this.classificationData = response.data;
            let promises = [];
            for (let entry of this.classificationData) {
                promises.push(DatabaseConnector.getCommodityCharges(entry.approved_classification_code.replace(/\./g, "")).then((chargesResponse) => {
                  let icdObject = chargesResponse.charges["import custom duty"];
                  const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
                  let vatObject = chargesResponse.charges["value added tax"];
                  const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
                  entry['approved_classification_code_vat'] = vatCharge;
                  entry['approved_classification_code_duty'] = icdCharge;
                  entry['approved_classification_description'] = chargesResponse.description;
                  console.log('Charges:', chargesResponse);
                }).catch((error) => {
                  console.error('Error fetching commodity charges:', error);
                }));

                promises.push(DatabaseConnector.getCommodityCharges(entry.llm_recommended_classification_code.replace(/\./g, "")).then((chargesResponse) => {
                  let icdObject = chargesResponse.charges["import custom duty"];
                  const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
                  let vatObject = chargesResponse.charges["value added tax"];
                  const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
                  entry['llm_recommended_classification_code_vat'] = vatCharge;
                  entry['llm_recommended_classification_code_duty'] = icdCharge;
                  entry['llm_recommended_classification_description'] = chargesResponse.description;
                }).catch((error) => {
                  console.error('Error fetching commodity charges:', error);
                }));
            }
        } catch (error) {
            this.error = error.message;
            console.error('Error fetching classification data:', error);
        }
      },
      openEntry(entryId){
        this.$router.push({ name: 'EntryDetails', params: { entryId } });
      },
    },
    computed: {
      filteredEntries() {
        if (!this.searchTerm) return this.entries;
  
        const term = this.searchTerm.toLowerCase();
        return this.entries.filter(entry => {
          return (
            (entry.llm_recommended_classification_code && entry.llm_recommended_classification_code.toLowerCase().includes(term)) ||
            (entry.approved_classification_code && entry.approved_classification_code.toLowerCase().includes(term)) ||
            (entry.description && entry.description.toLowerCase().includes(term)) ||
            (entry.supplier_name && entry.supplier_name.toLowerCase().includes(term)) ||
            (entry.entry_id && entry.entry_id.toString().toLowerCase().includes(term))
          );
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .classification-report-page {
    padding: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .error-message {
    color: red;
  }
  </style>
  