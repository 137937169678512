<template>
    <div class="customers-page">
      <h1>Customers List</h1>
      <form class="my-form-container" @submit.prevent="addCustomer">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="businessName"><strong>Business Name</strong></label>
                    <input type="text" class="form-control" id="businessName" placeholder="Please Enter Business Name" v-model="newCustomer.business_name" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="addressLine1"><strong>Address Line 1</strong></label>
                    <input type="text" class="form-control" id="addressLine1" placeholder="Please Enter Address Line 1" v-model="newCustomer.addressLine1" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="addressLine2"><strong>Address Line 2</strong></label>
                    <input type="text" class="form-control" id="addressLine2" placeholder="Please Enter Address Line 2" v-model="newCustomer.addressLine2" />
                </div> 
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="city"><strong>City</strong></label>
                    <input type="text" class="form-control" id="city" placeholder="Please Enter City" v-model="newCustomer.city" />
                </div> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="country"><strong>Country</strong></label>
                    <v-select
                        id="country"
                        v-model="newCustomer.country_id"
                        :options="countries"
                        label="name"
                        placeholder="Please Select Country"
                        :searchable="true"
                        :filterable="true"
                        :clearable="true"
                        :reduce="option => option.id">
                    </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="subscriptions">
                        <strong>Subscriptions</strong>
                    </label>
                    <v-select
                        id="subscriptions"
                        v-model="newCustomer.subscription_id"
                        :options="subscriptions"
                        label="tier"
                        placeholder="Please Select Subscription"
                        :searchable="true"
                        :filterable="true"
                        :clearable="true"
                        :reduce="option => option.id">
                    </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="taxIdentificationNumber"><strong>TIN Number</strong></label>
                    <input type="text" class="form-control" id="taxIdentificationNumber" placeholder="Please Enter TIN Number" v-model="newCustomer.tax_identification_number" />
                </div>
            </div>
            <div class="col-md-3">
            <div class="form-group">
              <p><strong></strong></p>
              <button class="btn btn-primary" type="submit">Add New Customer</button>
            </div>
          </div>
        </div>
      </form>
      <table class="table table-striped">
        <thead class="sticky-header">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Business Name</th>
            <th scope="col">Address</th>
            <th scope="col">Date Registered</th>
            <th scope="col">Status</th>
            <th scope="col">Expand</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(customer, index) in customers" :key="index">
            <tr>
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ customer.business_name }}</td>
              <td>{{ customer.address }}</td>
              <td>{{ customer.date_registered }}</td>
              <td>{{ customer.account_status }}</td>
              <td>
                <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                    <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr v-if="expandedIndex === index">
              <td colspan="6">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="businessName"><strong>Business Name</strong></label>
                            <input type="text" class="form-control" id="businessName" placeholder="Please Enter Business Name" v-model="customer.business_name" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label><strong>Contact Email</strong></label>
                            <input type="text" class="form-control" v-model="customer.contact_email" />
                        </div>
                    </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label><strong>Contact Phone</strong></label>
                      <input type="text" class="form-control" v-model="customer.contact_phone_number" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label><strong>Website</strong></label>
                      <input type="text" class="form-control" v-model="customer.website" />
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label><strong>Address</strong></label>
                            <textarea type="text" class="form-control" placeholder="Please Enter Address" v-model="customer.address" rows="4"></textarea>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="subscriptions">
                                        <strong>Subscriptions</strong>
                                    </label>
                                    <v-select
                                        id="subscriptions"
                                        v-model="customer.subscription_id"
                                        :options="subscriptions"
                                        label="tier"
                                        placeholder="Please Select Subscription"
                                        :searchable="true"
                                        :filterable="true"
                                        :clearable="true"
                                        :reduce="option => option.id">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="taxIdentificationNumber">TIN Number</label>
                                    <input type="text" class="form-control" id="taxIdentificationNumber" placeholder="Please Enter TIN Number" v-model="customer.tax_identification_number" />
                                </div>
                            </div> 
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="country"><strong>Country</strong></label>
                                    <v-select
                                        id="country"
                                        v-model="customer.country_id"
                                        :options="countries"
                                        label="name"
                                        placeholder="Please Select Country"
                                        :searchable="true"
                                        :filterable="true"
                                        :clearable="true"
                                        :reduce="option => option.id">
                                    </v-select>
                                </div>
                            </div> 
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <button class="btn btn-primary edit-form-button" @click="updatemawiCustomer(customer)">Update Customer </button>
                                <button class="btn btn-danger edit-form-button" >De-Activate Customer</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>

  import * as FastAPI from '@/composables/FastAPI';
  import * as SweetAlertConnector from '@/composables/SweetAlertConnector';
  
  export default {
    name: 'CustomersPage',
    data() {
      return {
        customers: [],
        countries: [],
        subscriptions: [],
        expandedIndex: null,
        newCustomer: {
          business_name: '',
          contact_email: '',
          contact_phone_number: '',
          website: '',
          address: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          subscription_id: null,
          tax_identification_number: '',
          country_id: null,
        },
      };
    },
    methods: {
        async addCustomer() {
            try {
                this.newCustomer.address = `${this.newCustomer.addressLine1},\n${this.newCustomer.addressLine2},\n${this.newCustomer.city}`;
                this.newCustomer = this.addClientSchema(this.newCustomer);
                console.log("Adding Customer", this.newCustomer);
                SweetAlertConnector.showLoadingAlert("Adding Customer");
                FastAPI.createCustomer(this.newCustomer).then((customer) => {
                    console.log("Customer Added Successfully", customer);
                    SweetAlertConnector.showSuccessAlert("Customer Added Successfully");
                    // this.customers.push(customer);
                    this.getCustomers();
                    this.newCustomer = {
                        business_name: '',
                        contact_email: '',
                        contact_phone_number: '',
                        website: '',
                        address: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        subscription_id: null,
                        tax_identification_number: '',
                        country_id: null,
                    };
                }).catch((error) => {
                    console.error("Error Adding Customer", error.message);
                    SweetAlertConnector.showErrorAlert("Error Adding Customer", error.message);
                });
            }
            catch(error){
                SweetAlertConnector.showErrorAlert("Error Adding Customer", error.message);
                console.error("There was a problem with the request:", error.message);
            }
        },
        async getSubscriptions(){
            try{
                this.subscriptions = await FastAPI.getSubscriptions();
                console.log("Subscriptions", this.subscriptions);
            }
            catch(error){
                console.error("There was a problem with the request:", error.message);
            }
        },
        toggleDetails(index) {
            this.expandedIndex = this.expandedIndex === index ? null : index;
        },
      async getCustomers() {
        try {
          this.customers = await FastAPI.getCustomers();
        } catch (error) {
          console.error("There was a problem with the request:", error.message);
          SweetAlertConnector.showErrorAlert("Error Fetching Customers", error.message);
        }
      },
      async updatemawiCustomer(customer) {
        try {
            console.log("Updating Customer", customer);
            SweetAlertConnector.showLoadingAlert("Updating Customer");
            FastAPI.updateCustomer(customer.id, customer).then((customerResult) => {
                console.log("Customer Updated Successfully", customerResult);
                SweetAlertConnector.showSuccessAlert("Customer Updated Successfully");
            }).catch((error) => {
                console.error("Error Updating Customer", error.message);
                SweetAlertConnector.showErrorAlert("Error Updating Customer", error.message);
            });
        }
        catch(error){
            SweetAlertConnector.showErrorAlert("Error Updating Customer", error.message);
            console.error("There was a problem with the request:", error.message);
        }
       },
       addClientSchema(mawiCustomer){
        if (mawiCustomer.business_name && !mawiCustomer.client_schema) {
            // Add client_schema based on business name
            mawiCustomer.client_schema = mawiCustomer.business_name
                .toLowerCase()
                .replace(/\s+/g, '_');
        }
        return mawiCustomer;
      },
      async getCountries() {
        this.countries = await FastAPI.getCountries();
       },
      getCountryName(id) {
        const country = this.countries.find(country => country.id === id);
        return country ? country.name : '';
      },
    },
    mounted() {
      this.getCustomers();
      this.getCountries();
      this.getSubscriptions();
    }
  }
  </script>
  
  <style scoped>
   .my-form-container {
    text-align: left;
  }
  .customers-page {
    padding: 20px;
    text-align: center;
  }
  
  .table {
    margin-top: 20px;
  }
  
  .sticky-header th {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  .edit-form-button {
    margin-right: 10px;
  }
  </style>
  