<template>
  <div class="entries-page">
    <h1>Entries Stored</h1>
    <!-- Search field -->
    <input type="text" v-model="searchTerm" placeholder="Search entries by entry number or importer name" @input="handleSearch" />

    <table class="table table-bordered table-striped">
      <thead class="sticky-header">
        <tr>
          <th scope="col">Entry #</th>
          <th>Importer</th>
          <th>Waybill Number</th>
          <th>Entry Date</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in entries" :key="entry.id">
          <td>{{ entry.entry_number }}</td>
          <td>{{  entry.waybill.importer?.name || "" }}</td>
          <td>{{ entry.waybill.waybill_number }}</td>
          <td>{{ entry.entry_date }}</td>
          <td>
            <button class="btn btn-primary" @click="openEntry(entry.id, entry.entry_type)">
              <i class="fa-solid fa-folder-open"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination controls -->
    <div class="pagination-controls">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>

    <div v-if="error" class="error-message">{{ error }}</div>
  </div>
</template>


<script>
import * as FastAPI from '@/composables/FastAPI';
import { ENTRY_TYPES } from '../constants';

export default {
  data() {
    return {
      entries: [],
      error: null,
      searchTerm: '', // To hold the search term
      currentPage: 1, // Track the current page
      pageSize: 15, // Number of entries per page
      isSearching: false, // To track if we are searching or paginating
      numberOfEntries: 0, // Total number of entries
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.numberOfEntries / this.pageSize);
    }
  },
  created() {
    this.fetchEntries(); // Fetch entries when the component is created
  },
  methods: {
    openEntry(entryId, entryType) {
      if (entryType && entryType === ENTRY_TYPES.VEHICLES) {
        this.$router.push({ name: 'VehicleEntryEditPage', params: { entryId } });
        return;
      }
      this.$router.push({ name: 'EntryDetails', params: { entryId } });
    },
    async fetchEntries() {
      try {
        // Fetch total count for pagination
        if (!this.isSearching) {
          this.numberOfEntries = await FastAPI.getCustomsEntriesCount({
            status: 'PARTIAL_STORAGE',
          });
          console.log('Number of entries:', this.numberOfEntries);
        }

        // Fetch entries with limit and offset
        const offset = (this.currentPage - 1) * this.pageSize;
        this.entries = await FastAPI.searchCustomsEntriesUpdated({ status: 'PARTIAL_STORAGE' }, this.pageSize, offset);
        console.log('Entries:', this.entries);
      } catch (error) {
        this.error = error.message;
        console.error('There was an error!', error);
      }
    },
    async handleSearch() {
      if (this.searchTerm) {
        try {
          this.isSearching = true; // Indicate that we are performing a search
          this.entries = await FastAPI.searchCustomsEntriesUpdated({ entry_number: this.searchTerm, status: 'PARTIAL_STORAGE' });
          console.log('Search result entries:', this.entries);
          this.isSearching = false; // Reset the flag after search
        } catch (error) {
          this.error = error.message;
          console.error('Search error!', error);
        }
      } else {
        this.isSearching = false; // Reset search flag if searchTerm is empty
        this.fetchEntries(); // Fetch default entries if no search term is entered
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchEntries();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchEntries();
      }
    }
  }
};

</script>

<style>
.entries-page {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.error-message {
  color: red;
}
.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}
</style>
