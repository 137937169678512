// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue'
import ViewEntries from '../components/viewEntries.vue';
import EntryDetails from '@/components/EntryDetails.vue';
import CustomsDeclarationForm from '@/components/CustomsDeclarationForm.vue';
import BuyersPage from '@/components/ConfigurationPages/BuyersPage.vue';
import SellersPage from '@/components/ConfigurationPages/SellersPage.vue';
import DeclarantsPage from '@/components/ConfigurationPages/DeclarantsPage.vue';
import VesselsPage from '@/components/ConfigurationPages/VesselsPage.vue';
import ExchangeRatesPage from '@/components/ConfigurationPages/RateOfExchangePage.vue';
import VehicleEntryCreatePage from '@/components/VehicleEntryCreatePage.vue';
import VehicleEntryEditPage from '@/components/VehicleEntryEditPage.vue';
import SuppliersPage from '@/components/ConfigurationPages/SuppliersPage.vue';
import BlankPage from '@/components/ConfigurationPages/BlankPage.vue';

import ApprovedClassificationEntries from '@/components/ApprovedClassificationEntries.vue';
import PartialStorageEntries from '@/components/PartialStorageEntries.vue';
import FullStorageEntries from '@/components/FullStorageEntries.vue';
import PerformanceDashboard from '@/components/PerformanceAnalysis/PerformanceDashboard.vue';
import AllEntries from '@/components/AllEntries.vue';
import ClassificationAccuracyReport from '@/components/PerformanceAnalysis/ClassificationAccuracyReport.vue';
import Login from '@/components/LoginForm.vue';
import Register from '@/components/RegisterForm.vue';
import PricingPage from '@/components/PricingPage.vue';
import ValueDeclarants from '@/components/ConfigurationPages/ValueDeclarants.vue';
import { isAuthenticated } from '@/composables/auth';
import LandingPage from '@/components/LandingPage.vue';
import MawiCustomers from '@/components/Admin/MawiCustomers.vue';
import CompanyAdmin from '@/components/Admin/CompanyAdmin.vue';


const routes = [
  {
    path: '/create-entry',
    name: 'NewEntry',
    component: HelloWorld,
    meta: { requiresAuth: true }
  },
  {
    path: '/view-entries',
    name: 'ViewEntries',
    component: ViewEntries,
    meta: { requiresAuth: true }
  },
  {
    path: '/entries/:entryId', // :entryId is a dynamic segment
    name: 'EntryDetails',
    component: EntryDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/customs-declaration/:entryId', // :entryId is a dynamic segment
    name: 'CustomsDeclarationForm',
    component: CustomsDeclarationForm,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/buyers',
    name: 'BuyersPage',
    component: BuyersPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/sellers',
    name: 'SellersPage',
    component: SellersPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/declarants',
    name: 'DeclarantsPage',
    component: DeclarantsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/value-declarants',
    name: 'ValueDeclarants',
    component: ValueDeclarants,
    meta: { requiresAuth: true }
  },
  {
    path: '/vessels',
    name: 'VesselsPage',
    component: VesselsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/suppliers',
    name: 'SuppliersPage',
    component: SuppliersPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/blank-page',
    name: 'BlankPage',
    component: BlankPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/rate-of-exchange',
    name: 'RateOfExchnagePage',
    component: ExchangeRatesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/create-vehicle-entry',
    name: 'VehicleEntry',
    component: VehicleEntryCreatePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-vehicle-entry/:entryId',
    name: 'VehicleEntryEditPage',
    component: VehicleEntryEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/approved-classification-entries',
    name: 'ApprovedClassificationEntries',
    component: ApprovedClassificationEntries,
    meta: { requiresAuth: true }
  },
  {
    path: '/partial-storage-entries',
    name: 'PartialStorageEntries',
    component: PartialStorageEntries,
    meta: { requiresAuth: true }
  },
  {
    path: '/full-storage-entries',
    name: 'FullStorageEntries',
    component: FullStorageEntries,
    meta: { requiresAuth: true }
  },
  {
    path: '/performance-dashboard',
    name: 'PerformanceDashboard',
    component: PerformanceDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-entries/:entryIds',
    name: 'AllEntries',
    component: AllEntries,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/classification-accuracy-report',
    name: 'ClassificationAccuracyReport',
    component: ClassificationAccuracyReport,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PricingPage,
  },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/mawi-customers',
    name: 'MawiCustomers',
    component: MawiCustomers,
  },
  {
    path: '/company-admin',
    name: 'CompanyAdmin',
    component: CompanyAdmin,

  }
];

const router = createRouter({
  history: createWebHistory(), // Use history mode to avoid hashes in URLs
  routes // short for `routes: routes`
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isAuthenticated()) {
          // Redirect to login if not authenticated
          next({
              path: '/login',
              query: { redirect: to.fullPath } // Store the route the user was trying to access
          });
      } else {
          // Proceed to the route
          next();
      }
  } else {
      // If the route doesn't require authentication, just proceed
      next();
  }
});

export default router;
