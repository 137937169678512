<template>
    <div class="users-page">
      <!-- <h1>Users List</h1> -->
    <div class="row mb-4">
        <div class="col-md-3">
            <div class="card text-center">
            <div class="card-body">
                <h5 class="card-title">Entries Created Today</h5>
                <!-- <p class="card-text">100</p> -->
                <p class="card-text">{{ this.entriesCreatedToday }}</p>
            </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card text-center">
            <div class="card-body">
                <h5 class="card-title">Entries Created in {{ currentMonth }} </h5>
                <p class="card-text">{{this.entriesCreatedThisMonth}}</p>
            </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card text-center">
            <div class="card-body">
                <h5 class="card-title">Active Users</h5>
                <p class="card-text">{{ this.users.length }}</p>
            </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card text-center">
            <div class="card-body">
                <h5 class="card-title">Total Cost for {{ currentMonth }}</h5>
                <p class="card-text">USD${{ this.costForCurrentMonth.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</p>
            </div>
            </div>
        </div>
    </div>
    <form class="my-form-container" @submit.prevent="addUser" autocomplete="off">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="firstName"><strong>First Name</strong></label>
                    <input type="text" class="form-control" id="firstName" placeholder="Please Enter First Name" v-model="newUser.first_name" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="lastName"><strong>Last Name</strong></label>
                    <input type="text" class="form-control" id="lastName" placeholder="Please Enter Last Name" v-model="newUser.last_name" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="email"><strong>Email Address</strong></label>
                    <input type="email" class="form-control" id="email" placeholder="Please Enter Email Address" autocomplete="new-email" v-model="newUser.email" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="role"><strong>Role</strong></label>
                    <input type="text" class="form-control" id="role" placeholder="Please Enter Role" v-model="newUser.role" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="password"><strong>Password</strong></label>
                    <input type="password" class="form-control" id="password" placeholder="Please Enter Password" v-model="newUser.password" autocomplete="new-password"/>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="confirmPassword"><strong>Confirm Password</strong></label>
                    <input type="password" class="form-control" id="confirmPassword" placeholder="Please Confirm Password" v-model="newUser.confirmPassword" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <p><strong></strong></p>
                    <button class="btn btn-primary" type="submit" @click="addNewUser">Create User</button>
                </div>
            </div>
        </div>
    </form>
  
      <!-- Table to Display Users -->
      <table class="table table-striped">
        <thead class="sticky-header">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Full Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Entries Created Today</th>
            <th scope="col">Entries Created in {{ currentMonth }}</th>
            <th scope="col">Expand</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(user, index) in users" :key="index">
            <tr>
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td> {{ user.entriesCreatedToday || 0 }}</td>
              <td> {{ user.entriesCreatedThisMonth || 0 }}</td>
              <td>
                <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                    <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
                </button>
              </td>
            </tr>
  
            <!-- Expanded Section for Editing User Details -->
            <tr v-if="expandedIndex === index">
              <td colspan="6">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="userFirstName"><strong>First Name</strong></label>
                      <input type="text" class="form-control" v-model="user.first_name" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="userLastName"><strong>Last Name</strong></label>
                      <input type="text" class="form-control" v-model="user.last_name" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="userEmail"><strong>Email Address</strong></label>
                      <input type="email" class="form-control" v-model="user.email" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="userRole"><strong>Role</strong></label>
                      <input type="text" class="form-control" v-model="user.role" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <button class="btn btn-primary edit-form-button" @click="updateUser(user)">Update User</button>
                    <button class="btn btn-danger edit-form-button" @click="deleteUser(user)">Delete User</button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import * as SweetAlertConnector from '@/composables/SweetAlertConnector';

  import * as FastAPI from '@/composables/FastAPI';

  export default {
    name: "UsersPage",
    props: {
      businessId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        users: [], // Users data will be populated here
        expandedIndex: null, // Track which row is expanded
        userDetails: {},
        selectedBusinessId: '',
        entriesCreatedToday: 0,
        entriesCreatedThisMonth: 0,
        costForCurrentMonth : 0,
        currentMonth: "",
        newUser: {
          first_name: "",
          last_name: "",
          email: "",
          role: "",
          password: "",
          confirmPassword: "",
        },
      };
    },
    methods: {
      // Method to fetch the users data
      async setup(){
        await this.getUserDetails();
        await this.getUsers();
        await this.getTotalEntriesCreatedToday();
        await this.getEntriesPerUser();
        this.getCurrentMonth();
        
      },
      getSelectedBusinessId(){
        if(this.businessId){
          this.selectedBusinessId = this.businessId;
          return;
        }
        this.selectedBusinessId = this.userDetails.mawi_business.id;
      },
      async getUserDetails(){
        this.userDetails = await FastAPI.getCurrentUser();
      },
      getCurrentMonth(){
          const date = new Date();
          this.currentMonth = date.toLocaleString('default', { month: 'long' });
      },
      async getUsers() {
        try {
            this.users = await FastAPI.getCompanyUsers(this.userDetails.mawi_business.id);
            console.log("Users:", this.users);
            this.getTotalEntriesCreatedThisMonth();
        } catch (error) {
            console.error("Failed to fetch users data", error);
        }
      },
      getCostForCurrentMonth(){
        const costForCurrentMonth = this.userDetails.mawi_business.subscription.cost;
        this.costForCurrentMonth = costForCurrentMonth
        console.log("Cost for current month", costForCurrentMonth)
        return costForCurrentMonth
      },
      async getTotalEntriesCreatedThisMonth() {
          const today = new Date();
          const yyyy = today.getFullYear();
          const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const dd = String(today.getDate()).padStart(2, '0');

          // Start of the month (yyyy-mm-01)
          const startOfMonth = `${yyyy}-${mm}-01`;

          // End of the month is today (yyyy-mm-dd)
          const endOfMonth = `${yyyy}-${mm}-${dd}`;

          const entryCount = await FastAPI.getCustomsEntriesCount({
              start_date: startOfMonth,
              end_date: endOfMonth,
          });

          console.log("Total Entries Created This Month:", entryCount);
          this.entriesCreatedThisMonth= entryCount;
          this.getCostForCurrentMonth();
          return entryCount;
      },

      async getTotalEntriesCreatedToday(){
        const formattedDate = this.getCurrentDateString();

        const entryCount = await FastAPI.getCustomsEntriesCount({
          start_date: formattedDate,
          end_date: formattedDate,
        });

        console.log("Entry Count:", entryCount);
        this.entriesCreatedToday = entryCount;
      },
      getCurrentDateString(){
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const dd = String(today.getDate()).padStart(2, '0');

        return `${yyyy}-${mm}-${dd}`;
      },
      getStartAndEndDayOfMonth(){
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const dd = String(today.getDate()).padStart(2, '0');

        // Start of the month (yyyy-mm-01)
        const startOfMonth = `${yyyy}-${mm}-01`;

        // End of the month is today (yyyy-mm-dd)
        const endOfMonth = `${yyyy}-${mm}-${dd}`;

        return { startOfMonth, endOfMonth };
      },
      async getEntriesPerUser(){
        let promises = [];
        const currentDateString = this.getCurrentDateString();
        const startAndEndOfMonth = this.getStartAndEndDayOfMonth();
        this.users.forEach(user => {
          promises.push(FastAPI.getCustomsEntriesCount({ user_id: user.id, start_date: currentDateString, end_date: currentDateString}).then((entryCount) => {
            user.entriesCreatedToday = entryCount;
          }));
          promises.push(FastAPI.getCustomsEntriesCount({ user_id: user.id, start_date: startAndEndOfMonth.startOfMonth, end_date: startAndEndOfMonth.endOfMonth}).then((entryCount) => {
            user.entriesCreatedThisMonth = entryCount;
          }));
        });

        await Promise.all(promises);
        console.log("Users with Entries:", this.users);
      },
      async addNewUser() {
        if (!this.newUser.first_name || !this.newUser.last_name || !this.newUser.email || !this.newUser.role || !this.newUser.password || !this.newUser.confirmPassword) {
            SweetAlertConnector.showErrorAlert("Please fill in all fields");
            return;
        }
        if (this.newUser.password !== this.newUser.confirmPassword) {
            SweetAlertConnector.showErrorAlert("Passwords do not match");
            this.newUser.password = "";
            this.newUser.confirmPassword = "";
            return;
        }
        
        SweetAlertConnector.showLoadingAlert("Adding New User...");
        try{
            this.newUser.business_id = this.userDetails.mawi_business.id;
            console.log("Adding New User:", this.newUser);
            await FastAPI.createUser(this.newUser);
            SweetAlertConnector.showSuccessAlert("User Added Successfully");
            this.newUser = {
                first_name: "",
                last_name: "",
                email: "",
                role: "",
                password: "",
                confirmPassword: "",
            };
            this.getUsers();
        } catch (error) {
            console.error("Failed to add new user", error);
            SweetAlertConnector.showErrorAlert("Failed to add new user", error);
        }
      },
      // Method to toggle the expanded details for a specific row
      toggleDetails(index) {
        this.expandedIndex = this.expandedIndex === index ? null : index;
      },
      // Method to format the date
      formatDate(dateString) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
      // Method to handle updating a user (simulate API call)
      updateUser(user) {
        console.log("Updating User:", user);
        SweetAlertConnector.showLoadingAlert("Updating User...");
        FastAPI.updateUser(user.id, user)
          .then(() => {
            SweetAlertConnector.showSuccessAlert("User Updated Successfully");
            this.getUsers();
          })
          .catch((error) => {
            console.error("Failed to update user", error);
            SweetAlertConnector.showErrorAlert("Failed to update user", error);
          });
      },
      // Method to handle deleting a user (simulate API call)
      deleteUser(user) {
        console.log("Deleting User:", user);
        // Here, you would make an API call to delete the user
        // For example: await FastAPI.deleteUser(user.id);
      },
    },
    mounted() {
      this.setup();
    },
  };
  </script>
  
  <style scoped>
  /* Add your custom styling here */
  .users-page{
    padding: 20px;
  }
  .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
  </style>
  