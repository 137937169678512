<template>
    <div class="container">
      <div class="card mt-5">
        <div class="card-body">
          <!-- Step Indicators -->
          <ul class="nav nav-pills mb-3">
            <li class="nav-item" v-for="(step, index) in steps" :key="index">
              <a
                class="nav-link"
                :class="{ active: currentStep === index + 1 }"
                @click="goToStep(index + 1)"
                href="#"
              >
                Step {{ index + 1 }}
              </a>
            </li>
          </ul>
  
          <!-- Step Content -->
          <div v-if="currentStep === 1">
            <!-- Step 1 -->
            <h4>Step 1: Personal Details</h4>
            <div class="form-group">
              <label for="name">Name</label>
              <input v-model="form.name" type="text" class="form-control" id="name" />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input v-model="form.email" type="email" class="form-control" id="email" />
            </div>
          </div>
  
          <div v-if="currentStep === 2">
            <!-- Step 2 -->
            <h4>Step 2: Address Details</h4>
            <div class="form-group">
              <label for="address">Address</label>
              <input v-model="form.address" type="text" class="form-control" id="address" />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input v-model="form.city" type="text" class="form-control" id="city" />
            </div>
          </div>
  
          <div v-if="currentStep === 3">
            <!-- Step 3 -->
            <h4>Step 3: Confirm & Submit</h4>
            <p><strong>Name:</strong> {{ form.name }}</p>
            <p><strong>Email:</strong> {{ form.email }}</p>
            <p><strong>Address:</strong> {{ form.address }}</p>
            <p><strong>City:</strong> {{ form.city }}</p>
          </div>
  
          <!-- Navigation Buttons -->
          <div class="mt-4">
            <button class="btn btn-secondary" @click="prevStep" :disabled="currentStep === 1">
              Previous
            </button>
            <button class="btn btn-primary" @click="nextStep" v-if="currentStep < steps.length">
              Next
            </button>
            <button class="btn btn-success" @click="submitForm" v-if="currentStep === steps.length">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentStep: 1,
        steps: [1, 2, 3, 4],
        form: {
          name: '',
          email: '',
          address: '',
          city: ''
        }
      };
    },
    methods: {
      nextStep() {
        if (this.currentStep < this.steps.length) {
          this.currentStep++;
        }
      },
      prevStep() {
        if (this.currentStep > 1) {
          this.currentStep--;
        }
      },
      goToStep(step) {
        this.currentStep = step;
      },
      submitForm() {
        // Handle form submission
        alert('Form submitted successfully!');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add some basic styling */
  .card {
    max-width: 600px;
    margin: auto;
  }
  </style>
  