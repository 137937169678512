<template>
    <div>
      <!-- You can add any necessary template content here if needed -->
    </div>
  </template>
  
  <script>
    import Swal from 'sweetalert2';
    import * as FastAPI from '@/composables/FastAPI';
    import * as SweetAlertConnector from '@/composables/SweetAlertConnector';

    async function showPartnerForm(title, initialName = '', addPartnerFunction) {
    // Fetch countries
    let countries = [];
    try {
        countries = await FastAPI.getCountries();
        countries.sort((a, b) => a.name.localeCompare(b.name));

        console.log("Countries", countries);
    } catch (error) {
        console.error("Failed to fetch countries", error);
    }

    const result = await Swal.fire({
        title: title,
        html: `
        <div class="swal2-input-group">
            <label for="swal-input1" class="swal2-label">Name:</label>
            <input id="swal-input1" class="swal2-input" placeholder="Enter name" value="${initialName}">
        </div>
        <div class="swal2-input-group">
            <label for="swal-input2" class="swal2-label">Address Line 1:</label>
            <input id="swal-input2" class="swal2-input" placeholder="Enter address line 1">
        </div>
        <div class="swal2-input-group">
            <label for="swal-input3" class="swal2-label">Address Line 2:</label>
            <input id="swal-input3" class="swal2-input" placeholder="Enter address line 2">
        </div>
        <div class="swal2-input-group">
            <label for="swal-input4" class="swal2-label">City:</label>
            <input id="swal-input4" class="swal2-input" placeholder="Enter city">
        </div>
        <div class="swal2-input-group">
            <label for="swal-input5" class="swal2-label">Country:</label>
            <select id="swal-input5" class="swal2-select">
            <option value="" disabled selected>Select a Country</option>
            ${countries.map(country => `<option value="${country.id}">${country.name}</option>`).join('')}
            </select>
        </div>
        <div class="swal2-input-group">
            <label for="swal-input6" class="swal2-label">TIN Number:</label>
            <input id="swal-input6" class="swal2-input" placeholder="Enter TIN number">
        </div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Add',
        cancelButtonText: 'Cancel',
        customClass: {
        container: 'custom-swal-container'
        },
        didOpen: () => {
        // Add custom styles to the Swal modal
        const style = document.createElement('style');
        style.textContent = `
            .custom-swal-container .swal2-html-container {
            margin: 1em 1.6em 0.3em;
            }
            .custom-swal-container .swal2-input-group {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            }
            .custom-swal-container .swal2-label {
            flex: 0 0 120px;
            text-align: right;
            margin-right: 10px;
            }
            .custom-swal-container .swal2-input,
            .custom-swal-container .swal2-select {
            flex: 1;
            margin: 0;
            }
        `;
        document.head.appendChild(style);
        },
        preConfirm: () => {
        const name = document.getElementById('swal-input1').value;
        const addressLine1 = document.getElementById('swal-input2').value;
        const addressLine2 = document.getElementById('swal-input3').value;
        const city = document.getElementById('swal-input4').value;
        const country = document.getElementById('swal-input5').value;
        const tinNumber = document.getElementById('swal-input6').value;

        if (!name) {
            Swal.showValidationMessage('Please fill in all required fields');
            return false;
        }

        return { name, addressLine1, addressLine2, city, country, tinNumber };
        }
    });

    if (result.isConfirmed && result.value) {
        try {
        SweetAlertConnector.showLoadingAlert(`Adding ${title}`);
        
        const newPartner = {
            name: result.value.name,
            address: `${result.value.addressLine1 ? result.value.addressLine1 : ''}\n${result.value.addressLine2 ? result.value.addressLine2 : ''}\n${result.value.city ? result.value.city : ''}`.trim() || null,
            tax_identification_number: result.value.tinNumber || null,
            country: result.value.country || null
        };

        const createdPartner = await FastAPI.createPartner(newPartner);
        console.log(`New Partner. Setting to ${title}`, createdPartner);
        
        const createdImporter = await addPartnerFunction({ partner_id: createdPartner.id });
        SweetAlertConnector.showSuccessAlert(`${title} Added Successfully`);
        
        return createdImporter;
        } catch (error) {
        console.error(`Failed to add ${title}`, error);
        SweetAlertConnector.showErrorAlert(`Failed to Add ${title}`);
        return null;
        }
    }

    return null;
  }


  
export async function showVesselForm(initialName = '') {
    let countries = [];
    try {
        countries = await FastAPI.getCountries();
        countries.sort((a, b) => a.name.localeCompare(b.name));

        console.log("Countries", countries);
    } catch (error) {
        console.error("Failed to fetch countries", error);
    }
  const result = await Swal.fire({
    title: 'Add New Vessel',
    html: `
      <div class="swal2-input-group">
        <label for="swal-input1" class="swal2-label">Vessel Name:</label>
        <input id="swal-input1" class="swal2-input" placeholder="Enter vessel name" value="${initialName}">
      </div>
      <div class="swal2-input-group">
        <label for="swal-input2" class="swal2-label">IMO Number:</label>
        <input id="swal-input2" class="swal2-input" placeholder="Enter IMO number">
      </div>

      <div class="swal2-input-group">
            <label for="swal-input3" class="swal2-label">Country:</label>
            <select id="swal-input3" class="swal2-select">
            <option value="" disabled selected>Select a Country</option>
            ${countries.map(country => `<option value="${country.id}">${country.name}</option>`).join('')}
            </select>
        </div>
    `,
    focusConfirm: false,
    showCancelButton: true,
    confirmButtonText: 'Add Vessel',
    cancelButtonText: 'Cancel',
    customClass: {
      container: 'custom-swal-container'
    },
    didOpen: () => {
      // Add custom styles to the Swal modal
      const style = document.createElement('style');
      style.textContent = `
        .custom-swal-container .swal2-html-container {
          margin: 1em 1.6em 0.3em;
        }
        .custom-swal-container .swal2-input-group {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
        .custom-swal-container .swal2-label {
          flex: 0 0 120px;
          text-align: right;
          margin-right: 10px;
        }
        .custom-swal-container .swal2-input {
          flex: 1;
          margin: 0;
        }
      `;
      document.head.appendChild(style);
    },
    preConfirm: () => {
      const vesselName = document.getElementById('swal-input1').value;
      const imoNumber = document.getElementById('swal-input2').value;
      const country = document.getElementById('swal-input3').value;

      if (!vesselName || !imoNumber) {
        Swal.showValidationMessage('Please fill in all fields');
        return false;
      }

      return { vesselName, imoNumber,country };
    }
  });

  if (result.isConfirmed && result.value) {
    try {
      SweetAlertConnector.showLoadingAlert('Adding Vessel');
      
      const newVessel = {
        name: result.value.vesselName,
        imo_number: result.value.imoNumber,
        registered_country_id: result.value.country,
        type: "",
        gross_tonnage: 0,
        length_overall: 0
      };
      await FastAPI.addVessel(newVessel);
      SweetAlertConnector.showSuccessAlert('Vessel Added Successfully');
      
      return newVessel;
    } catch (error) {
      console.error("Failed to add Vessel", error);
      SweetAlertConnector.showErrorAlert('Failed to Add Vessel', error);
      return null;
    }
  }
  
  return null;
}
  
  export async function showImporterForm(initialName = '') {
    return showPartnerForm('Add New Importer', initialName, FastAPI.addImporter);
  }
  
  export async function showExporterForm(initialName = '') {
    return showPartnerForm('Add New Exporter', initialName, FastAPI.addExporter);
  }
  
  export default {
    name: 'AddForms',
    // Your component options here if needed
  }
  </script>
  ```