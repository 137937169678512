<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="sellerNameAddress"><strong>1. Name and Address of Seller (Block Letters)</strong></label>
          <select class="form-select" v-model="localData.sellerId" @change="updateField('sellerNameAddress', $event.target.value)" :disabled="true">
            <option value="" disabled>Select a Seller</option>
            <option v-for="(seller, index) in localData.sellers" :key="index" :value="seller.id">{{ seller.name }}</option>
          </select>
          <!-- <v-select
            v-model="localData.sellerId"
            :options="localData.sellers"
            label="name"
            :searchable="true"
            :reduce="seller => seller.id"
            :filterable="true"
            placeholder="Select a Seller"
          ></v-select> -->
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            id="sellerNameAddress"
            rows="4"
            @input="updateField('sellerNameAddress', $event.target.value)"
            required
            readonly
            :value="selectedSeller"
          ></textarea>
        </div>"
      </div>
      <div class="col-md-6">
        <div class="form-group"> 
          <label for="buyerNameAddress"><strong>2(a) Name and Address of Buyer (Block Letters)</strong></label>
          <select class="form-select" v-model="localData.buyerId" @change="updateField('buyerNameAddress', $event.target.value)" :disabled="true">
            <option value="" disabled>Select a Buyer</option>
            <option v-for="(buyer, index) in localData.buyers" :key="index" :value="buyer.id">{{ buyer.name }}</option>
          </select>
          <!-- <v-select
            v-model="localData.buyerId"
            :options="localData.buyers"
            label="name"
            :searchable="true"
            :reduce="buyer => buyer.id"
            :filterable="true"
            placeholder="Select a Buyer"
          ></v-select> -->
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            id="buyerNameAddress"
            rows="4"
            @input="updateField('buyerNameAddress', $event.target.value)"
            required
            readonly
            :value="selectedBuyer"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="declarantNameAddress"><strong>2(b) Name and Address of Declarant (Block Letters)</strong></label>
          <select class="form-select" v-model="localData.declarantId" @change="updateField('declarantNameAddress', $event.target.value)">
            <option value="" disabled>Select a Declarant</option>
            <option v-for="(declarant, index) in localData.declarants" :key="index" :value="declarant.id">{{ declarant.name }}</option>
          </select>
          <!-- <v-select
            v-model="localData.declarantId"
            :options="localData.declarants"
            label="name"
            :searchable="true"
            :reduce="declarant => declarant.id"
            :filterable="true"
            placeholder="Select a Declarant"
            @input="updateField('declarantNameAddress', $event.target.value)"
          ></v-select> -->
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            id="declarantNameAddress"
            rows="4"
            @input="updateField('declarantNameAddress', $event.target.value)"
            required
            readonly
            :value="selectedDeclarant"
          ></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="invoiceNumber" class="form-label"><strong>4. Number and Date of Invoice</strong></label>
          <textarea
            type="text"
            class="form-control"
            id="invoiceNumber"
            rows="6"
            placeholder="e.g., 202313 2024/10/20"
            v-model="localData.invoiceNumber"
            @input="updateField('invoiceNumber', $event.target.value)"
            required
          ></textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <!-- Section 3: Terms of Delivery (INCOTERM) -->
        <div class="form-group">
          <label for="termsOfDelivery" class="form-label"><strong>3. Terms of Delivery (INCOTERM)</strong></label>
          <select class="form-select" v-model="localData.termsOfDelivery" @change="updateField('termsOfDelivery', $event.target.value)" :disabled="true">
            <option value="" disabled>Select one Incoterm</option>
            <option v-for="(incoterm, index) in localData.incoterms" :key="index" :value="incoterm.id">{{ incoterm.code }} - {{ incoterm.name }}</option>
          </select>
          <!-- <v-select
            v-model="localData.termsOfDelivery"
            :options="incoterms"
            label="name"
            :searchable="true"
            :reduce="incoterm => incoterm.code"
            :filterable="true"
            placeholder="Select an Incoterm"
          ></v-select> -->
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="form-group">
          <label for="invoiceNumber" class="form-label"><strong>4. Number and Date of Invoice</strong></label>
          <input
            type="text"
            class="form-control"
            id="invoiceNumber"
            placeholder="e.g., 202313 2024/10/20"
            v-model="localData.invoiceNumber"
            @input="updateField('invoiceNumber', $event.target.value)"
            required
          >
        </div>
      </div> -->
      <div class="col-md-6">
        <!-- Section 5: Number and Date of Contract -->
        <div class="form-group">
          <label for="contractNumber" class="form-label"><strong>5. Number and Date of Contract</strong></label>
          <input
            type="text"
            class="form-control"
            id="contractNumber"
            placeholder="e.g., 123456 2024/10/22"
            v-model="localData.contractNumber"
            @input="updateField('contractNumber', $event.target.value)"
            required
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as FastAPI from '@/composables/FastAPI';
export default {
  props: ['customsDeclarationSectionOne', 'buyerIdProp'],
  name: 'CustomsDeclarationSectionOne',
  mounted() {
    // this.getConfigurations();
    // this.getIncoTerms();
  },
  data() {
    return {
      localData: this.customsDeclarationSectionOne,
      myBuyerId: this.buyerIdProp,
      incoterms: [],
    };
  },
  computed: {
    selectedSeller() {
      const selectedSeller = this.localData.sellers.find(seller => seller.id === this.localData.sellerId);
      const sellerNameAddress = selectedSeller ? `${selectedSeller.name} \n ${selectedSeller.address}` : '';
      return sellerNameAddress;
    },
    selectedBuyer() {
      const selectedBuyer = this.localData.buyers.find(buyer => buyer.id === this.localData.buyerId);
      return selectedBuyer ? `${selectedBuyer.name} \n ${selectedBuyer.address}` : '';
    },
    selectedDeclarant() {
      const selectedDeclarant = this.localData.declarants.find(declarant => declarant.id === this.localData.declarantId);
      return selectedDeclarant ? `${selectedDeclarant.name} \n ${selectedDeclarant.address}` : '';
    },
    declarantStatus() {
      const selectedDeclarant = this.declarants.find(declarant => declarant.id === this.localData.declarantId);
      return selectedDeclarant ? selectedDeclarant.status : '';
    }
  },
  methods: {
    updateField(field, value) {
      this.localData[field] = value;
      this.$emit('update-section-one', this.localData);
    },

    async getConfigurations() {
      console.log('Fetching sellers, buyers, and declarants');
        Promise.all([FastAPI.getExporters(), FastAPI.getImporters(), FastAPI.getVessels(), FastAPI.getValueDeclarants(), FastAPI.getCountries()]).then(([exporters, importers, vessels, declarants, countries]) => { 
          this.localData.sellers = exporters;
            this.localData.buyers = importers;
            console.log('Buyers:', importers);
            this.localData.vessels = vessels;
            this.localData.declarants = declarants;
            console.log('Declarants:', declarants);
            this.countryList = countries;
        });
    }
  },
  watch: {
    customsDeclarationSectionOne: {
      deep: true,
      handler(newVal) {
        this.localData = {...newVal};
      }
    }
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check label {
  margin-right: 1rem;
}
</style>
