    <template>
    <MultiStepForm />
  </template>
  
  <script>
  import MultiStepForm from '../MultiStepForm.vue';
  export default {
    name: 'BlankPage',
    components: {
    MultiStepForm,
  },
  };
  </script>
  
  <style scoped>
  /* Styling for the blank page */
  .blank-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  </style>
  