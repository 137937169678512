<template>
  <div class="buyers-page">
    <h1>Importers List</h1>
    <form class="my-form-container" @submit.prevent="addBuyer">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerName"><strong>Name</strong></label>
            <input type="text" class="form-control" id="buyerName" placeholder="Please Enter Buyer Name" v-model="buyerName" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerAddressLine1"><strong>Address Line 1</strong></label>
            <input type="text" class="form-control" id="buyerAddressLine1" placeholder="Please Enter Address Line 1" v-model="buyerAddressLine1" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerAddressLine2"><strong>Address Line 2</strong></label>
            <input type="text" class="form-control" id="buyerAddressLine2" placeholder="Please Enter Address Line 2" v-model="buyerAddressLine2" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerCity"><strong>City</strong></label>
            <input type="text" class="form-control" id="buyerCity" placeholder="Please Enter City" v-model="buyerCity" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerCountry"><strong>Country</strong></label>
            <!-- <input type="text" class="form-control" id="buyerCountry" v-model="buyerCountry" /> -->
            <!-- <select class="form-select" v-model="buyerCountry" id="buyerCountry">
              <option disabled value="">Please select one</option>
              <option v-for="country in this.countries" :key="country.code" :value="country.code">
                {{ country.code }} - {{ country.name }}
              </option>
            </select> -->
            <v-select :options="countries" v-model="buyerCountry" label="name" :searchable="true" :reduce="country => country.id"  :filterable="true" placeholder="Select a Country"></v-select>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label for="tinNumber"><strong>TIN Number</strong></label>
            <input type="text" class="form-control" id="tinNumber" placeholder="TIN Number" v-model="tinNumber" />
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <p><strong></strong></p>
            <button class="btn btn-primary" type="submit">Add Importer</button>
           </div>
        </div>
      </div> 
    </form>
    <table class="table table-striped">
      <thead class="sticky-header">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Address </th>
          <th scope="col">Country</th>
          <th scope="col">TIN Number</th>
          <th scope="col">Expand</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(buyer, index) in buyers" :key="index">
          <tr >
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ buyer.name }}</td>
            <td>{{ buyer.address }}</td>
            <td>{{ getCountryName(buyer.country) }}</td>
            <td>{{ buyer.taxIdentificationNumber }}</td>
            <td>
              <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                      <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                      <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
              </button>
            </td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="6">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="buyerName"><strong>Name</strong></label>
                    <input type="text" class="form-control" id="buyerName" placeholder="Please Enter Buyer Name" v-model="buyer.name" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="tinNumber"><strong>Tax Identification Number</strong></label>
                    <input type="text" class="form-control" id="tinNumber" placeholder="Please Enter TIN Number" v-model="buyer.taxIdentificationNumber" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="buyerAddress"><strong>Address</strong></label>
                    <textarea  type="text" class="form-control" id="buyerAddressLine1" placeholder="Please Enter Address" v-model="buyer.address" rows="4"></textarea>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="buyerCountry"><strong>Country</strong></label>
                    <v-select :options="countries"
                    v-model="buyer.country"
                    label="name"
                    :searchable="true"
                    :filterable="true"
                    :reduce="option => option.id"
                    ></v-select>
                  </div>
                </div>
              </div>  
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <button class="btn btn-primary edit-form-button" @click="updateBuyer(buyer)">Update Importer</button>
                    <button class="btn btn-danger edit-form-button" @click="deleteBuyer(buyer)">Delete Importer</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        
      </tbody>
    </table>
  </div>
</template>

<script>

import * as SweetAlertConnector from '@/composables/SweetAlertConnector';

import * as FastAPI from '@/composables/FastAPI';

export default {
  name: 'BuyersPage',
  data() {
    return {
      buyers : [],
      countries: [],
      buyerName: '',
      buyerAddressLine1: '',
      buyerAddressLine2: '',
      buyerCity: '',
      buyerCountry: '',
      tinNumber: '',
      expandedIndex: null,
    };
  },
  methods: {
    toggleDetails(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
    async getBuyers() {
      this.buyers = await FastAPI.getImporters();
      console.log(this.buyers);
    },
    async updateBuyer(buyer){
      const updatedBuyer = {
          ...buyer, // Copy all other properties
          tax_identification_number: buyer.taxIdentificationNumber // Rename the key
      };
      console.log("Updating Importer", updatedBuyer);
      SweetAlertConnector.showLoadingAlert('Updating Importer');
      FastAPI.updatePartner(buyer.partner_id, updatedBuyer).then(() => {
        SweetAlertConnector.showSuccessAlert('Importer Updated Successfully');
        this.getBuyers();
      }).catch((error) => {
        console.error("Failed to update Buyer", error);
        SweetAlertConnector.showErrorAlert('Failed to Update Importer', error);
      });
    },
    async deleteBuyer(buyer){
      SweetAlertConnector.showLoadingAlert('Deleting Importer');
      FastAPI.deletePartner(buyer.partner_id).then(() => {
        SweetAlertConnector.showSuccessAlert('Importer Deleted Successfully');
        this.getBuyers();
      }).catch((error) => {
        SweetAlertConnector.showErrorAlert('Failed to Delete Importer', error);
      });
    },
    async addBuyer() {
      const newBuyer = {
        name: this.buyerName,
        address: `${this.buyerAddressLine1}\n${this.buyerAddressLine2}\n${this.buyerCity}`,
        phone_contact: '',
        tax_identification_number: this.tinNumber,
      };
      if (this.buyerCountry && this.buyerCountry !== '') {
          newBuyer.country = this.buyerCountry;
      }
      console.log("New Buyer", newBuyer);
      SweetAlertConnector.showLoadingAlert('Adding Importer');
      try{
        FastAPI.searchPartners(newBuyer.name).then(async (partners) => {
          if(partners.length > 0){
            console.log("Partner already exists.  checking for importerts", partners);
            const importer = partners.find(partner => partner.importer);
            if(importer){
              console.log("Importer already exists", importer);
              SweetAlertConnector.showErrorAlert('Importer Already Exists', 'Importer with the same name already exists');
              return;
            }
            console.log("Partner exists but not importer. Setting to importer", partners);
            const newImporterRequest = {
              partner_id: partners[0].id
            };
            await FastAPI.addImporter(newImporterRequest)
            console.log("Set to importter Successfully", newImporterRequest);
            await FastAPI.updatePartner(newImporterRequest.partner_id, newBuyer);
            console.log("Updated Importer Successfully", newBuyer);
            SweetAlertConnector.showSuccessAlert('Importer Added Successfully');
            this.buyerName = '';
            this.buyerAddressLine1 = '';
            this.buyerAddressLine2 = '';
            this.buyerCity = '';
            this.buyerCountry = '';
            this.tinNumber = '';
            this.getBuyers();
            return;
          }
          console.log("Partner does not exist. Creating new partner", partners);
          const newImporter = await FastAPI.createPartner(newBuyer);
          console.log("New Partner. Setting to importer", newImporter);
          const importer = {
            partner_id: newImporter.id
          };
          await FastAPI.addImporter(importer);
          SweetAlertConnector.showSuccessAlert('Importer Added Successfully');
          this.buyerName = '';
          this.buyerAddressLine1 = '';
          this.buyerAddressLine2 = '';
          this.buyerCity = '';
          this.buyerCountry = '';
          this.tinNumber = '';
          this.getBuyers();
          });
      }
      catch(error){
        console.error("Failed to add Importer", error);
        SweetAlertConnector.showErrorAlert('Failed to Add Importer', error);
      }
      
    },
    async getCountries() {
      this.countries = await FastAPI.getCountries();
      console.log("Countries", this.countries);
    },
    getCountryName(id){
      const country = this.countries.find(country => country.id === id);
      return country ? country.name : '';
    }
  },
  mounted() {
    this.getBuyers();
    this.getCountries();
    // Any code you want to run after the component is mounted
  }
}
</script>

<style scoped>
.buyers-page {
  padding: 20px;
  text-align: center;
}

.customs-declaration-section-two {
    /* Your styles here */
  }
  .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
</style>
