<template>
  <div class="form-body without-side">
    <div class="website-logo">
      <a href="index.html">
        <div class="logo">
          <img class="logo-size" src="@/assets/images/logo-light.svg" alt="Logo" />
        </div>
      </a>
    </div>
    <div class="iofrm-layout">
      <div class="img-holder">
        <div class="bg"></div>
        <div class="info-holder">
          <img src="@/assets/images/graphic8.svg" alt="Graphic" />
        </div>
      </div>
      <div class="form-holder">
        <div class="form-content">
          <div class="form-items">
            <div class="form-icon">
              <div class="icon-holder">
                <img src="@/assets/images/icon1.svg" alt="Icon" />
              </div>
            </div>
            <h3 class="form-title-center">Login Now</h3>
            <form @submit.prevent="handleLogin">
              <input class="form-control custom-input" type="text" v-model="username" placeholder="E-mail Address" required />
              <input class="form-control custom-input" type="password" v-model="password" placeholder="Password" required />
              <div class="form-button">
                <button id="submit" type="submit" class="ibtn ibtn-full">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as FastAPI from '@/composables/FastAPI';
import * as SweetAlertConnector from "@/composables/SweetAlertConnector";


export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async handleLogin() {
      console.log('Logging in... sample');
      try {
        console.log('Logging in... sample', this.username, this.password);
        const token = await FastAPI.loginUser(this.username, this.password);
        console.log('Token:', token);
        if (token) {
            console.log('Login successful!');
            SweetAlertConnector.showSuccessAlert("Successfully logged in!");
            this.$router.push({ path: '/create-entry' });
        } else {
            console.error('Login failed!');
            SweetAlertConnector.showErrorAlert('Invalid username or password.');
        }
      }catch (error) {
        SweetAlertConnector.showErrorAlert('Invalid username or password.');
        console.error('Login failed!', error);
      }
    },
  },
};
</script>

<style scoped>
@import '@/assets/css/iofrm-style.css';
@import '@/assets/css/iofrm-theme27.css';

/* Ensure input fields are always visible */
.custom-input {
  border: 1px solid #ccc; /* Light gray border */
  padding: 10px; /* Add some padding for better visibility */
  border-radius: 4px; /* Rounded corners for a modern look */
  margin-bottom: 15px; /* Space between the fields */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.custom-input:focus {
  border-color: #007bff; /* Bootstrap primary color on focus */
  outline: none; /* Remove the default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a blue shadow for focus */
}

/* Additional styles for form layout */
.form-body.without-side {
  /* Add specific styles if needed */
}
</style>
