<template>
  <div class="pt-60 pb-120">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center pt-20 pb-60">
          <h1>Pricing Plans</h1>
        </div>
        <div v-for="(plan, index) in pricingPlans" :key="index" class="col-md-4">
          <div class="pricing-package-1-style-11 bg-1 transition pt-20 pb-20 mb-30">
            <h2 class="heading transition">{{ plan.title }}</h2>
            <h3 class="subheading transition">{{ plan.description }}</h3>
            <h6 class="duration-top transition">{{ plan.duration }}</h6>
            <h5 class="price transition">
              <span class="currency"><i class="fa fa-dollar"></i></span><span class="counter">{{ plan.price }}</span>
            </h5>
            <button @click="goToRegisterPage" class="cmk-button sm cmk-slideout-vertical">
              <span class="after-button-icon">
                <i class="fa fa-arrow-right"></i>
              </span>
              Buy Now
            </button>
            <div class="features-list">
              <ul>
                <li v-for="(feature, i) in plan.features" :key="i">
                  <span :style="{ color: feature.included ? 'green' : 'red' }">
                    <i :class="feature.included ? 'fa fa-check' : 'fa fa-close'"></i>
                  </span>
                  {{ feature.text }}
                </li>
              </ul>
            </div>
            
            <!-- <div class="special-note">{{ plan.discount }}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pricingPlans: [
        {
          title: 'Individual',
          description: 'Perfect individuals or small businesses',
          duration: 'Per Month',
          price: 10,
          features: [
            { text: '10 GB SSD Hosting', included: true },
            { text: '250 GB Bandwidth', included: true },
            { text: '2 Websites', included: true },
            { text: 'FREE SSL Certificates', included: true },
            { text: 'LiteSpeed with LSCache', included: true },
            { text: 'cPanel', included: false },
          ],
          discount: '10% Discount',
        },
        {
          title: 'Corporate',
          description: 'Perfect for Small to Medium Business',
          duration: 'Per Month',
          price: 20,
          features: [
            { text: '10 GB SSD Hosting', included: true },
            { text: '250 GB Bandwidth', included: true },
            { text: '2 Websites', included: true },
            { text: 'FREE SSL Certificates', included: true },
            { text: 'LiteSpeed with LSCache', included: true },
            { text: 'cPanel', included: false },
          ],
          discount: '10% Discount',
        },
        {
          title: 'Enterprise',
          description: 'Perfect for Multi-National Business',
          duration: 'Per Month',
          price: 30,
          features: [
            { text: '10 GB SSD Hosting', included: true },
            { text: '250 GB Bandwidth', included: true },
            { text: '2 Websites', included: true },
            { text: 'FREE SSL Certificates', included: true },
            { text: 'LiteSpeed with LSCache', included: true },
            { text: 'cPanel', included: false },
          ],
          discount: '10% Discount',
        },
      ],
    };
  },
  methods: {
    goToRegisterPage() {
      this.$router.push({ path: '/register' });
    },
  }
};
</script>

<style scoped>
/* @import '@/assets/css/bootstrap.min.css'; Ensure Bootstrap is imported */
/* @import '@/assets/css/reset.css';
@import '@/assets/css/osteo.css'; */

/* Additional styles if needed */
/* .pricing-package-1-style-11 {
  text-align: center;
}

.cmk-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.cmk-button:hover {
  background-color: #0056b3;
} */
</style>
