<template>
    <div class="customs-entry-form">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label class="required" for="buyerNameAddress"><strong>Importer</strong></label>
                    <v-select
                        :options="localData.importers"
                        v-model="localData.importerId"
                        label="name"
                        :searchable="true"
                        :reduce="(importer) => importer.id"
                        :filterable="true"
                        placeholder="Select an Importer"
                    >
                        <template #no-options="{ search, searching }">
                            <div>
                                <span v-if="searching">No importers found for "{{ search }}"</span>
                                <span v-else>No importers found</span>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm mt-2 d-block w-100"
                                    @click="addNewImporter(search)"
                                >
                                    Add New Importer
                                </button>
                            </div>
                        </template>
                        <template #option="option">
                            <div
                                class="option-wrapper"
                                :data-bs-toggle="'tooltip'"
                                :data-bs-placement="'right'"
                                :title="option.tooltipContent"
                            >
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="sellerNameAddress"><strong>Exporter</strong></label>
                    <v-select
                        :options="localData.exporters"
                        v-model="localData.exporterId"
                        label="name"
                        :searchable="true"
                        :reduce="(exporter) => exporter.id"
                        :filterable="true"
                        placeholder="Select an Exporter"
                    >
                        <template #no-options="{ search, searching }">
                            <div>
                                <span v-if="searching">No exporters found for "{{ search }}"</span>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm mt-2 d-block w-100"
                                    @click="addNewExporter(search)"
                                >
                                    Add New Exporter
                                </button>
                            </div>
                        </template>
                        <template #option="option">
                            <div
                                class="option-wrapper"
                                :data-bs-toggle="'tooltip'"
                                :data-bs-placement="'right'"
                                :title="option.tooltipContent"
                            >
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="modeOfTransport"><strong>Mode of Transport</strong></label>
                    <v-select
                        :options="localData.modeOfTransportOOptions"
                        v-model="localData.modeOfTransport"
                        label="name"
                        :searchable="true"
                        :reduce="(mode) => mode.id"
                        :filterable="true"
                        placeholder="Select a Mode of Transport"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="freightType"><strong>Freight Type</strong></label>
                    <v-select
                        :options="availableFreightTypes"
                        v-model="localData.freightType"
                        label="name"
                        :searchable="true"
                        :reduce="(freight) => freight.id"
                        :filterable="true"
                        placeholder="Select a Freight Type"
                    ></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="waybillNumber"><strong>Waybill Numbers</strong></label>
                    <input
                        type="text"
                        class="form-control"
                        id="waybillNumber"
                        v-model="localData.waybillNumber"
                        placeholder="Please Enter Waybill Number"
                        required
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="waybillDate"><strong>Waybill Date (DD/MM/YYYY)</strong></label>
                    <input type="date" class="form-control" id="waybillDate" v-model="localData.waybillDate" required />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="vessel"><strong>Vessel</strong></label>
                    <v-select
                        :options="localData.vessels"
                        v-model="localData.vesselId"
                        label="name"
                        :searchable="true"
                        :reduce="(vessel) => vessel.id"
                        :filterable="true"
                        placeholder="Select a Vessel"
                    >
                        <template #no-options="{ search, searching }">
                            <div>
                                <span v-if="searching">No Vessel found for "{{ search }}"</span>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm mt-2 d-block w-100"
                                    @click="addNewVessel(search)"
                                >
                                    Add New Vessel
                                </button>
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="grossWeight"><strong>Gross Weight</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="grossWeight"
                        v-model="localData.grossWeight"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="portOfLoading"><strong>Port of Loading (Office of Entry)</strong></label>
                    <v-select
                        :options="localData.ports"
                        v-model="localData.portOfLoadingId"
                        label="name"
                        :searchable="true"
                        :reduce="(port) => port.id"
                        :filterable="true"
                        placeholder="Select a Port of Loading"
                        :filter="customOfficeFilter"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="portOfDischarge"><strong>Port of Discharge (Office of Exit)</strong></label>
                    <v-select
                        :options="localData.ports"
                        v-model="localData.portOfDischargeId"
                        label="name"
                        :searchable="true"
                        :reduce="(port) => port.id"
                        :filterable="true"
                        placeholder="Select a Port of Discharge"
                        :filter="customOfficeFilter"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="numberOfPackages"><strong>Number of Packages</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="numberOfPackages"
                        v-model="localData.numberOfPackages"
                        required
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="packageType"><strong>Package Type</strong></label>
                    <v-select
                        :options="localData.packageTypes"
                        v-model="localData.packageType"
                        label="text"
                        :searchable="true"
                        :reduce="(type) => type.id"
                        :filterable="true"
                        placeholder="Select a Package Type"
                        :filter="customFilter"
                    ></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="marksAndNumbers"><strong>Marks and Numbers</strong></label>
                    <input
                        type="text"
                        class="form-control"
                        id="marksAndNumbers"
                        v-model="localData.marksAndNumbers"
                        required
                    />
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="currency"><strong>Freight</strong></label>
                    <v-select
                        :clearable="false"
                        :options="currencies"
                        v-model="localData.freightCurrency"
                        label="code"
                        id="invoiceCurrency"
                        :searchable="true"
                        :filterable="true"
                        :reduce="(currency) => currency.id"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="freightAmount" class="label-green-italic">{{ localData.freightRateOfExchange }}</label>
                    <input
                        type="number"
                        class="form-control"
                        id="freightAmount"
                        v-model="localData.freightAmount"
                        required
                    />
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="currency"><strong>Insurance</strong></label>
                    <v-select
                        :clearable="false"
                        :options="currencies"
                        v-model="localData.insuranceCurrency"
                        label="code"
                        id="invoiceCurrency"
                        :searchable="true"
                        :filterable="true"
                        :reduce="(currency) => currency.id"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-2">
                <label for="InsuranceAmount" class="label-green-italic">{{ localData.insuranceRateOfExchange }}</label>
                <input
                    type="number"
                    class="form-control"
                    id="InsuranceAmount"
                    v-model="localData.insuranceAmount"
                    required
                />
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="currency"><strong>Charges</strong></label>
                    <v-select
                        :clearable="false"
                        :options="currencies"
                        v-model="localData.otherChargesCurrency"
                        label="code"
                        id="invoiceCurrency"
                        :searchable="true"
                        :filterable="true"
                        :reduce="(currency) => currency.id"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="othercharges" class="label-green-italic">{{
                        localData.otherChargesRateOfExchange
                    }}</label>
                    <input type="number" class="form-control" id="otherCharges" v-model="localData.otherCharges" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="declarant"><strong>Declarant</strong></label>
                    <v-select
                        :options="localData.declarants"
                        v-model="localData.declarantId"
                        label="name"
                        :searchable="true"
                        :reduce="(declarant) => declarant.id"
                        :filterable="true"
                        placeholder="Select a Declarant"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="regimeType"><strong>Regime Type</strong></label>
                    <v-select
                        :options="localData.regimeTypeOptions"
                        v-model="localData.regimeType"
                        label="regimeType"
                        :searchable="true"
                        :reduce="(regime) => regime.regimeTypeId"
                        :filterable="true"
                        placeholder="Select a Regime Type"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <label for="incoTerms"><strong>Incoterms</strong></label>
                <v-select
                    :options="localData.incotermsList"
                    v-model="localData.incoTerms"
                    label="name"
                    :searchable="true"
                    :reduce="(incoterm) => incoterm.id"
                    :filterable="true"
                    placeholder="Select an Incoterm"
                ></v-select>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="totalCargoValue"><strong>Total Cargo Value</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="totalCargoValue"
                        v-model="localData.totalCargoValue"
                        required
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="containerCharges"><strong>Container Charges</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="containerCharges"
                        v-model="localData.containerCharges"
                        required
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="localFee"><strong>Local Fee</strong></label>
                    <input type="number" class="form-control" id="localFee" v-model="localData.localFee" required />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="depositAmount"><strong>Deposit Amount</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="depositAmount"
                        v-model="localData.depositAmount"
                        required
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="additionalCharges"><strong>Additional Charges</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="additionalCharges"
                        v-model="localData.additionalCharges"
                        required
                    />
                </div>
            </div>
            <!-- <div class="col-md-3">
                <label for="country"><strong>Country of Origin</strong></label>
                <select class="form-select" v-model="localData.countryOfOrigin" id="country" @change="countryChange">
                  <option disabled value="">Please select one</option>
                  <option v-for="country in countryList" :key="country.code" :value="country.code">
                    {{ country.code }} - {{ country.name }}
                  </option>
                </select>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-3">
                <label for="country"><strong>Country of Export</strong></label>
                <v-select
                    :options="localData.countryList"
                    v-model="localData.countryOfOrigin"
                    label="name"
                    :searchable="true"
                    :reduce="(country) => country.id"
                    :filterable="true"
                    placeholder="Select a Country of Origin"
                    :filter="customCountryFilter"
                ></v-select>
            </div>
            <div class="col-md-3">
                <label for="country"><strong>Country of Final Destination</strong></label>
                <v-select
                    :options="localData.countryList"
                    v-model="localData.countryOfFinalDestination"
                    label="name"
                    :searchable="true"
                    :reduce="(country) => country.id"
                    :filterable="true"
                    placeholder="Select a Country of Destination"
                    :filter="customCountryFilter"
                ></v-select>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="countryLastProvinance"><strong>Country of Last Provinance</strong></label>
                    <v-select
                        :options="localData.countryList"
                        v-model="localData.countryLastProvinance"
                        label="name"
                        :searchable="true"
                        :reduce="(country) => country.id"
                        :filterable="true"
                        placeholder="Select a Country of Last Provinance"
                        :filter="customCountryFilter"
                    ></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="tradingCountry"><strong>Trading Country</strong></label>
                    <v-select
                        :options="localData.countryList"
                        v-model="localData.tradingCountry"
                        label="name"
                        :searchable="true"
                        :reduce="(country) => country.id"
                        :filterable="true"
                        placeholder="Select a Trading Country"
                        :filter="customCountryFilter"
                    ></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="estimatedArrivalDate"><strong>Arrival Date</strong></label>
                    <input
                        type="date"
                        class="form-control"
                        id="estimatedArrivalDate"
                        v-model="localData.estimatedArrivalDate"
                        required
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="locationOfGoods"><strong>Location of Goods</strong></label>
                    <v-select :options="availableLocationOfGoods" v-model="localData.locationOfGoodsId" label="name" :searchable="true" :reduce="location => location.id"  :filterable="true" placeholder="Select a Location of Goods"></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <label for="manifestReference"><strong>Manifest Reference</strong></label>
                <input type="text" class="form-control" id="manifestReference" v-model="localData.manifestReference" placeholder="Enter Manifest Reference">
            </div>
            <div class="col-md-3">
                <label for="deliveryPlace"><strong>Delivery Place</strong></label>
                <input type="text" class="form-control" id="deliveryPlace" v-model="localData.deliveryPlace" placeholder="Enter Delivery Place">
            </div>
        </div>
            
            
    </div>
</template>

<script>
import * as Utilities from "@/composables/Utilities";
import * as FastAPI from "@/composables/FastAPI";
import * as SweetAlertConnector from "@/composables/SweetAlertConnector";
import { showImporterForm, showExporterForm, showVesselForm } from "@/components/CustomsEntry/AddForms.vue";

export default {
    name: "CustomsEntryForm",
    props: ["customsEntryForm", "createMode"],
    mounted() {
        console.log("Mounting");
        console.log("Mode:", this.createMode);
        this.getConfigurations();
        this.getIncoTerms();
        this.initTooltips();
        this.getCurrencies();
    },
    data() {
        return {
            freightTypeOptions: [],
            localData: this.customsEntryForm,
            countryList: [],
            currencies: [],
        };
    },
    computed: {
        availableFreightTypes() {
            const results = this.localData.freightTypeOptions
                ? this.localData.freightTypeOptions.filter(
                      (freightType) => freightType.mode_of_transport_id === this.localData.modeOfTransport
                  )
                : [];
            return results;
        },
        availableLocationOfGoods(){
            return this.localData.portOfDischargeId && this.localData.ports.length > 0 ? this.localData.ports.find(port => port.id === this.localData.portOfDischargeId)?.locationOfGoods : [];
        },
        // availableFreightTypes(){
        //     const results = this.localData.freightTypeOptions ? this.localData.freightTypeOptions.filter(freightType => freightType.mode_of_transport_id === this.localData.modeOfTransport) : [];
        //     return results;
        // },
    },
    updated() {
        // Re-initialize Bootstrap tooltips if options change
        this.initTooltips();
    },
    methods: {
        customFilter(optionArray, search) {
            if (!search) return optionArray;

            const searchLower = search.toLowerCase();

            return optionArray.filter(option => {
                const nameMatches = option.text.toLowerCase().includes(searchLower);
                const codeMatches = option.value.toLowerCase().includes(searchLower);
                return nameMatches || codeMatches;
            });
        },
        customOfficeFilter(optionArray, search) {
            console.log("Filtering", optionArray, search);
            if (!search) return optionArray;

            const searchLower = search.toLowerCase();

            return optionArray.filter(option => {
                console.log(option);
                const nameMatches = option.name.toLowerCase().includes(searchLower);
                const codeMatches = option.asycudaCode?.toLowerCase().includes(searchLower);
                return nameMatches || codeMatches;
            });
        },
        customCountryFilter(optionArray, search) {
            if (!search) return optionArray;

            const searchLower = search.toLowerCase();

            return optionArray.filter(option => {
                const nameMatches = option.name.toLowerCase().includes(searchLower);
                const codeMatches = option.code.toLowerCase().includes(searchLower);
                return nameMatches || codeMatches;
            });
        },
        async addNewImporter(initialName = "") {
            const result = await showImporterForm(initialName);
            if (result) {
                console.log(result)
                SweetAlertConnector.showSuccessAlert("New importer added successfully!");
                await this.getConfigurations();
                this.localData.importerId = result.id;
            }
        },
        async addNewExporter(initialName = "") {
            const newExporter = await showExporterForm(initialName);
            if (newExporter) {
                this.localData.exporterId = newExporter;
                await this.getConfigurations();
            }
        },
        async addNewVessel(initialName = "") {
            const newVessel = await showVesselForm(initialName);
            if (newVessel) {
                this.localData.vesselId = newVessel;
                await this.getConfigurations();
            }
        },

        async getRatesOfExchange(currency, shippedOnBoardDate) {
            if (
                currency !== null &&
                currency !== "" &&
                currency !== undefined &&
                shippedOnBoardDate !== null &&
                shippedOnBoardDate !== "" &&
                shippedOnBoardDate !== undefined
            ) {
                return Utilities.getRatesOfExchange([currency], shippedOnBoardDate)
                    .then((rates) => {
                        console.log(rates);
                        return rates[0];
                    })
                    .catch((error) => {
                        console.error("Error fetching rates of exchange", error);
                        return 0;
                    });
            } else {
                return 0;
            }
        },
        async getIncoTerms() {
            try {
                this.localData.incotermsList = await FastAPI.getIncoTerms();
            } catch (error) {
                console.error("Error fetching incoterms", error);
            }
        },
        async getCurrencies() {
            try {
                this.currencies = await FastAPI.getCurrencies();
                // this.localData.freightCurrency = this.currencies.find((currency) => currency.code === "USD").id;
                // this.localData.insuranceCurrency = this.currencies.find((currency) => currency.code === "USD").id;
                // this.localData.otherChargesCurrency = this.currencies.find((currency) => currency.code === "USD").id;
            } catch (error) {
                console.error("Error fetching currencies", error);
            }
        },
        initTooltips() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                new window.bootstrap.Tooltip(tooltipTriggerEl);
            });
        },
        setModeOfTransport(modeOfTransport) {
            this.$nextTick(() => {
                this.localData.modeOfTransport = modeOfTransport;
                this.$refs.modeOfTransportSelect.dispatchEvent(new Event("change"));
            });
        },
        updateModeOfTransport(event) {
            const modeOfTransport = this.localData.modeOfTransportOOptions.find(
                (mode) => mode.modeOfTransport === event.target.value
            );
            this.freightTypeOptions = modeOfTransport.freightTypes;
        },
        updateField(field, value) {
            this.localData[field] = value;
            console.log(this.localData);
            this.$emit("update-section-one", this.localData);
        },
        async getConfigurations() {
            console.log("Fetching configurations...");
            this.localData.ports = await FastAPI.getOffices();

            Promise.all([
                FastAPI.getExporters(),
                FastAPI.getImporters(),
                FastAPI.getVessels(),
                FastAPI.getDeclarants(),
                FastAPI.getCountries(),
                FastAPI.getPackageTypes(),
                FastAPI.getModesOfTransport(),
                FastAPI.getFreightTypes(),
                FastAPI.getCurrentUser(),
            ]).then(
                ([
                    exporters,
                    importers,
                    vessels,
                    declarants,
                    countries,
                    packageTypes,
                    modesOfTransport,
                    allFreightTypes,
                    userDetails,
                ]) => {
                    this.localData.exporters = exporters.map((exporter) => {
                        return {
                            ...exporter,
                            tooltipContent: `${exporter.name}\n${exporter.address}\n${exporter.country}`,
                        };
                    });
                    this.localData.importers = importers.map((importer) => {
                        return {
                            ...importer,
                            tooltipContent: `${importer.name}\n${importer.address}\n${importer.country}`,
                        };
                    });
                    this.localData.modeOfTransportOOptions = modesOfTransport;
                    this.localData.freightTypeOptions = allFreightTypes;
                    this.localData.vessels = vessels;
                    this.localData.declarants = declarants;
                    if(this.createMode !== null && this.createMode !== undefined && this.createMode === true){
                        if (declarants.length > 0) {
                            this.localData.declarantId = declarants[0].id;
                        }
                        this.localData.incoTerms = userDetails.mawi_business.country.default_delivery_terms_id;
                        this.localData.localFee = userDetails.mawi_business.country.default_local_fee;
                        this.localData.waybillDate = new Date().toISOString().split("T")[0];
                        this.localData.deliveryPlace = userDetails.mawi_business.country.default_delivery_terms_place;
                        this.localData.countryOfFinalDestination = userDetails.mawi_business.country_id;
                    }
                    countries.sort((a, b) => a.name.localeCompare(b.name));
                    this.localData.countryList = countries;

                    this.localData.packageTypes = packageTypes;

                this.localData.userDetails = userDetails;
                console.log("User Details", userDetails);
                
                console.log("Default Incoterms", userDetails.mawi_business.default_delivery_terms_id);
                
                
            });


            
        }
    },
    watch: {
        "localData.freightCurrency"(newVal) {
            if (this.localData.waybillDate != null && this.localData.waybillDate !== "") {
                const shippedOnBoardDate = this.localData.waybillDate;
                Utilities.getRatesOfExchange([newVal], shippedOnBoardDate).then((rates) => {
                    Utilities.mergeArrays(rates, this.localData.ratesOfExchange);
                });
            }
        },
        "localData.insuranceCurrency"(newVal) {
            if (this.localData.waybillDate != null && this.localData.waybillDate !== "") {
                const shippedOnBoardDate = this.localData.waybillDate;
                Utilities.getRatesOfExchange([newVal], shippedOnBoardDate).then((rates) => {
                    Utilities.mergeArrays(rates, this.localData.ratesOfExchange);
                });
            }
        },
        "localData.otherChargesCurrency"(newVal) {
            if (this.localData.waybillDate != null && this.localData.waybillDate !== "") {
                const shippedOnBoardDate = this.localData.waybillDate;
                Utilities.getRatesOfExchange([newVal], shippedOnBoardDate).then((rates) => {
                    Utilities.mergeArrays(rates, this.localData.ratesOfExchange);
                });
            }
        },
        customsEntryForm: {
            deep: true,
            handler(newVal) {
                // console.log("Updating form")
                this.localData = newVal;
                this.localData.portOfDischargeName = this.localData.ports.find(
                    (port) => port.id === this.localData.portOfDischargeId
                )?.name;
                this.localData.portOfLoadingName = this.localData.ports.find(
                    (port) => port.id === this.localData.portOfLoadingId
                )?.name;
                this.localData.vesselName = this.localData.vessels.find(
                    (vessel) => vessel.id === this.localData.vesselId
                )?.name;
                this.localData.regimeTypeCode = this.localData.regimeTypeOptions.find(
                    (regime) => regime.regimeTypeId === this.localData.regimeType
                )?.regimeTypeCode;
                const importer = this.localData.importers.find((importer) => importer.id === this.localData.importerId);
                const exporter = this.localData.exporters.find((exporter) => exporter.id === this.localData.exporterId);
                this.localData.importerName = importer !== undefined ? importer.name : "";
                this.localData.importerTaxIdentificationNumber =
                    importer !== undefined ? importer.taxIdentificationNumber : "";
                this.localData.exporterName = exporter !== undefined ? exporter.name : "";
                this.localData.exporterTaxIdentificationNumber =
                    exporter !== undefined ? exporter.taxIdentificationNumber : "";
                this.localData.exporterCountry = exporter !== undefined ? exporter.country : "";

                if (
                    this.localData.countryOfOrigin !== null &&
                    (this.localData.countryLastProvinance == null ||
                        this.localData.countryLastProvinance == "" ||
                        this.localData.countryLastProvinance == undefined)
                ) {
                    this.localData.countryLastProvinance = this.localData.countryOfOrigin;
                }

                if (
                    this.localData.countryOfOrigin !== null &&
                    (this.localData.tradingCountry == null ||
                        this.localData.tradingCountry == "" ||
                        this.localData.tradingCountry == undefined)
                ) {
                    this.localData.tradingCountry = this.localData.countryOfOrigin;
                }

                if (this.localData.grossWeight !== null && this.localData.grossWeight > 0) {
                    this.localData.netWeight = this.localData.grossWeight - this.localData.grossWeight / 10;
                }

                if (this.localData.ratesOfExchange !== null && this.localData.ratesOfExchange.length > 0) {
                    if (this.localData.insuranceCurrency) {
                        const insuranceRate = this.localData.ratesOfExchange.find(
                            (rate) => rate.base_currency_id === this.localData.insuranceCurrency
                        );
                        this.localData.insuranceRateOfExchange = insuranceRate ? insuranceRate.exchange_rate : 1; // Default to 1 if not found
                        this.localData.insuranceExchangeRateId = insuranceRate ? insuranceRate.id : 0;
                    } else {
                        this.localData.insuranceRateOfExchange = 1; // Default to 1 if insuranceCurrency is falsy
                    }

                    // Check and assign freight rate of exchange
                    if (this.localData.freightCurrency) {
                        const freightRate = this.localData.ratesOfExchange.find(
                            (rate) => rate.base_currency_id === this.localData.freightCurrency
                        );
                        this.localData.freightRateOfExchange = freightRate ? freightRate.exchange_rate : 1; // Default to 1 if not found
                        this.localData.freightExchangeRateId = freightRate ? freightRate.id : 0;
                    } else {
                        this.localData.freightRateOfExchange = 1;
                        // Default to 1 if freightCurrency is falsy
                    }

                    // Check and assign other charges rate of exchange
                    if (this.localData.otherChargesCurrency) {
                        const otherChargesRate = this.localData.ratesOfExchange.find(
                            (rate) => rate.base_currency_id === this.localData.otherChargesCurrency
                        );
                        this.localData.otherChargesRateOfExchange = otherChargesRate
                            ? otherChargesRate.exchange_rate
                            : 1; // Default to 1 if not found
                        this.localData.otherChargesExchangeRateId = otherChargesRate ? otherChargesRate.id : 0;
                    } else {
                        this.localData.otherChargesRateOfExchange = 1; // Default to 1 if otherChargesCurrency is falsy
                    }
                }
            },
        },
    },
};
</script>

<style scoped>
@import "vue3-select/dist/vue3-select.css";
.btn-sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
}

.option-wrapper {
    padding: 5px;
    cursor: pointer;
}
.customs-entry-form {
    text-align: left;
    padding: 20px;
}
.form-group {
    margin-bottom: 20px; /* Adds space below each form group */
}
.form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
}
.form-check label {
    margin-right: 1rem;
}

.required::after {
    content: " *";
    color: red;
}
.label-green-italic {
    color: green;
    font-style: italic;
}
/* Custom Tooltip Styling */
.tooltip {
    background-color: #333; /* Darker background */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Extra padding */
    font-size: 14px; /* Adjust font size */
    line-height: 1.5; /* Improved readability */
}

.tooltip .tooltip-arrow {
    display: none; /* Hide the default arrow */
}

.tooltip-inner {
    color: #fff; /* White text */
    background-color: #333; /* Darker background */
    padding: 8px 12px; /* Padding around the content */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
    text-align: left; /* Left-align the text */
}

/* Custom Arrow using :before */
.tooltip[data-popper-placement^="right"] .tooltip-inner::before,
.tooltip[data-popper-placement^="left"] .tooltip-inner::before,
.tooltip[data-popper-placement^="top"] .tooltip-inner::before,
.tooltip[data-popper-placement^="bottom"] .tooltip-inner::before {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    z-index: 1;
}

/* Right placement arrow */
.tooltip[data-popper-placement^="right"] .tooltip-inner::before {
    border-color: transparent #333 transparent transparent;
    border-width: 8px;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
}

/* Left placement arrow */
.tooltip[data-popper-placement^="left"] .tooltip-inner::before {
    border-color: transparent transparent transparent #333;
    border-width: 8px;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
}

/* Top placement arrow */
.tooltip[data-popper-placement^="top"] .tooltip-inner::before {
    border-color: #333 transparent transparent transparent;
    border-width: 8px;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
}

/* Bottom placement arrow */
.tooltip[data-popper-placement^="bottom"] .tooltip-inner::before {
    border-color: transparent transparent #333 transparent;
    border-width: 8px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
